/** @jsx jsx */
import { useCallback, useMemo, useState } from "react";
import { Label, Flex, jsx, Badge, Text, Checkbox } from "theme-ui";
import PropTypes from "prop-types";
import { get, noop } from "../../utils/lodash";

import Select from "../ui/Select";
import InputWithIcon from "../ui/InputWithIcon";
import RadioInline from "../ui/RadioInline";
import PhoneNumberInput from "../ui/PhoneNumberInput";
import PhotoInput from "../ui/PhotoInput";
import City from "../ui/City";
import Country from "../ui/Country";
import State from "../ui/State";
import Substate from "../ui/Substate";
import Group from "../ui/SrcmGroup";
import Preceptor from "../ui/Preceptor";
import "../fontawesome";

const gender_options = [
  { name: "", label: "" },
  { name: "F", label: "Female" },
  { name: "M", label: "Male" },
  { name: "U", label: "Other" },
];

// const serverAutoSuggestVars = {
//   srcmgroup_id: "srcmgroup_id",
//   city_id: "city_id",
//   state_id: "state_id",
//   country_id: "country_id",
//   city_id_nostring_allowned: "city_id_nostrict_allownew",
//   city_id_strict: "city_id_nostrict",
//   preceptor_idname: "preceptor_idname",
//   srcmgroup_idname: "srcmgroup_idname",
//   city_idname: "city_idname",
//   country_idname: "country_idname",
//   state_idname: "state_idname",
// };

export const DynamicField = ({
  RenderPrint,
  type,
  name,
  label,
  icon,
  options,
  onChange,
  placeholder,
  containerStyle,
  value: currentValue,
  inputTransform,
  sbt,
  ...restprops
}) => {
  // const { theme } = useThemeUI();

  // console.log("theme in dynamicfield", theme);

  const inputOnChange = useCallback(
    (e) => {
      if (type === "number") {
        const x = e.target.value.replace(/^\D+/g, "");
        onChange(inputTransform(x ? parseInt(x, 10) : ""), name);
      } else if (type === "phone") {
        onChange(inputTransform(e.target.value).replace(/[^0-9+]/g, ""));
      } else if (type === "email") {
        onChange(inputTransform(e.target.value.trim()), name);
      } else if (type === "abhyasi_ref" || type === "abhyasi_ref_temp") {
        onChange(inputTransform(e.target.value.trim()).toUpperCase(), name);
      } else {
        onChange(inputTransform(e.target.value), name);
      }
    },
    [onChange, name, type, inputTransform]
  );
  const { onBlur: onBlur1 } = restprops;
  const inputOnBlur = useCallback(
    (e) => {
      // if (type === "text") {
      onChange(e.target.value.trim(), name);
      if (onBlur1) {
        onBlur1(e);
      }
      // }
    },
    [name, onChange, onBlur1]
  );

  const cbOnChange1 = useCallback((v) => onChange(v, name), [name, onChange]);
  const cbOnChange2 = useCallback(
    (cityId, cityObj) =>
      onChange(
        { id: cityId, name: get(cityObj || {}, "name", "") },
        name,
        !options
          ? {}
          : Object.fromEntries(
              Object.entries(options).map(([s, d]) => [
                s,
                get(cityObj || {}, d, ""),
              ])
            )
      ),
    [name, options, onChange]
  );

  const cbOnChange3 = useCallback(
    (cityId, cityObj) =>
      onChange(
        cityId,
        name,
        !options
          ? {}
          : Object.fromEntries(
              Object.entries(options).map(([s, d]) => [
                s,
                get(cityObj || {}, d, ""),
              ])
            )
      ),
    [name, options, onChange]
  );
  const cbOnChange4 = useCallback(
    (option) =>
      onChange(typeof option === "string" ? option : option.name, name),
    [name, onChange]
  );

  const cbOnChange5 = useCallback(
    (v) => onChange(`+${v}`, name),
    [name, onChange]
  );

  const renderPrint = useMemo(
    () =>
      typeof RenderPrint === "function"
        ? (value) => <RenderPrint label={label} value={value} />
        : null,
    [RenderPrint, label]
  );

  let includeDflt = [];

  switch (type) {
    case "radio-inline":
      return (
        <Flex my={2}>
          <RadioInline
            options={options}
            name={name}
            value={currentValue}
            onClick={cbOnChange4}
            onChange={noop}
            renderPrint={renderPrint}
            {...restprops}
          />
        </Flex>
      );
    case "select":
      return (
        <Select
          value={currentValue}
          onChange={cbOnChange1}
          options={options}
          renderPrint={renderPrint}
          {...restprops}
        />
      );

    case "gender":
      return (
        <Select
          value={currentValue}
          onChange={cbOnChange1}
          options={gender_options}
          renderPrint={renderPrint}
          {...restprops}
        />
      );

    case "phone":
      return (
        <PhoneNumberInput
          value={currentValue}
          onChange={cbOnChange5}
          renderPrint={renderPrint}
          {...restprops}
        />
      );

    case "photo":
      return (
        <PhotoInput
          value={currentValue}
          onChange={cbOnChange1}
          {...restprops}
        />
      );

    case "preceptor_idname":
      if (!sbt) {
        if (!renderPrint) {
          throw Error("Invalid Configuration for Preceptor");
        } else {
          includeDflt = currentValue ? [currentValue] : [];
        }
      } else {
        includeDflt = get(restprops, "includeDflt", []);
      }

      return (
        <Preceptor
          icon={icon}
          placeholder={placeholder}
          sbt={sbt}
          {...restprops}
          includeDflt={includeDflt}
          onChange={cbOnChange2}
          value={currentValue ? currentValue.id : ""}
          valueDisplay={currentValue ? currentValue.name : ""}
          renderPrint={renderPrint}
        />
      );

    case "srcmgroup_idname":
      return (
        <Group
          icon={icon}
          placeholder={placeholder}
          {...restprops}
          onChange={cbOnChange2}
          value={currentValue ? currentValue.id : ""}
          valueDisplay={currentValue ? currentValue.name : ""}
          renderPrint={renderPrint}
        />
      );

    case "srcmgroup_id":
      return (
        <Group
          icon={icon}
          placeholder={placeholder}
          value={currentValue}
          onChange={cbOnChange3}
          {...restprops}
          renderPrint={renderPrint}
        />
      );

    case "city_idname":
      return (
        <City
          icon={icon}
          placeholder={placeholder}
          {...restprops}
          onChange={cbOnChange2}
          value={currentValue ? currentValue.id : ""}
          valueDisplay={currentValue ? currentValue.name : ""}
          renderPrint={renderPrint}
        />
      );

    case "state_idname":
      return (
        <State
          icon={icon}
          placeholder={placeholder}
          {...restprops}
          onChange={cbOnChange2}
          value={currentValue ? currentValue.id : ""}
          valueDisplay={currentValue ? currentValue.name : ""}
          renderPrint={renderPrint}
        />
      );
    case "substate_idname":
      return (
        <Substate
          icon={icon}
          placeholder={placeholder}
          {...restprops}
          onChange={cbOnChange2}
          value={currentValue ? currentValue.id : ""}
          valueDisplay={currentValue ? currentValue.name : ""}
          renderPrint={renderPrint}
        />
      );
    case "country_idname":
      return (
        <Country
          icon={icon}
          placeholder={placeholder}
          {...restprops}
          onChange={cbOnChange2}
          value={currentValue ? currentValue.id : ""}
          valueDisplay={currentValue ? currentValue.name : ""}
          renderPrint={renderPrint}
        />
      );
    case "city_id":
    case "city_id_nostrict":
    case "city_id_nostrict_allownew":
      return (
        <City
          icon={icon}
          placeholder={placeholder}
          value={currentValue}
          onChange={cbOnChange3}
          {...restprops}
          noStrictId={
            type === "city_id_nostrict_allownew" || type === "city_id_nostrict"
          }
          allowNewCities={type === "city_id_nostrict_allownew"}
          renderPrint={renderPrint}
        />
      );
    case "state_id":
      return (
        <State
          icon={icon}
          placeholder={placeholder}
          value={currentValue}
          onChange={cbOnChange3}
          {...restprops}
          renderPrint={renderPrint}
        />
      );
      case "substate_id":
        return (
          <Substate
            icon={icon}
            placeholder={placeholder}
            value={currentValue}
            onChange={cbOnChange3}
            {...restprops}
            renderPrint={renderPrint}
          />
        );
    case "country_id":
      return (
        <Country
          icon={icon}
          placeholder={placeholder}
          value={currentValue}
          onChange={cbOnChange3}
          {...restprops}
          renderPrint={renderPrint}
        />
      );

    // case "date":
    //   if (get(restprops, "readOnly") || get(restprops, "disabled")) {
    //     return (
    //       <InputWithIcon
    //         type="text"
    //         icon={icon}
    //         placeholder={placeholder}
    //         {...restprops}
    //         value={
    //           currentValue
    //             ? new Date(currentValue).toString().substring(0, 15)
    //             : ""
    //         }
    //       />
    //     );
    //   }

    default:
      return (
        <InputWithIcon
          type={type}
          icon={icon}
          placeholder={placeholder}
          {...restprops}
          value={currentValue || ""}
          onChange={inputOnChange}
          renderPrint={renderPrint}
          onBlur={inputOnBlur}
        />
      );
  }
};

export const DynamicFieldStyle1 = ({
  type,
  label,
  hideLabel,
  value: currentValue,
  containerStyle,
  helperText,
  error,
  showChanged,
  prevValue,
  ...restprops
}) => {
  const [focussed, setFocussed] = useState(false);
  const entered = useCallback(() => setFocussed(true), []);
  const exited = useCallback(() => setFocussed(false), []);

  const showLabel = !!currentValue || focussed;
  const placeholder = showLabel ? "" : label;
  const { name, onChange } = restprops;
  const revert = useCallback(
    () => onChange(prevValue, name),
    [name, onChange, prevValue]
  );
  // const { theme } = useThemeUI();

  // console.log("theme in dynamicfield1", theme);
  return (
    <div sx={containerStyle}>
      {type !== "radio-inline" && !hideLabel && (
        <Label
          sx={{
            paddingLeft: "6px",
            height: "1rem",
            fontSize: "0.8rem",
            fontWeight: "300",
            fontFamily: "body",
          }}
        >
          {showChanged && (
            <Checkbox
              sx={{ marginTop: "-6px", marginRight: "2px", cursor: "pointer" }}
              checked
              onChange={revert}
            />
          )}
          {showLabel ? label : <div> &nbsp; </div>}
        </Label>
      )}
      {type === "radio-inline" ? (
        <Flex my={2}>
          <DynamicField
            type={type}
            label={label}
            hideLabel={hideLabel}
            value={currentValue}
            placeholder={placeholder}
            {...restprops}
          />
        </Flex>
      ) : (
        <Flex
          sx={{
            "> div": {
              flexGrow: "1",
            },
          }}
        >
          <DynamicField
            onFocus={entered}
            onBlur={exited}
            label={label}
            type={type}
            hideLabel={hideLabel}
            value={currentValue}
            placeholder={placeholder}
            {...restprops}
          />
        </Flex>
      )}
      {helperText && (
        <Text variant="description" sx={{ mx: 1 }}>
          {helperText}
          <br />
        </Text>
      )}
      {error && (
        <Badge variant="danger" sx={{ ml: 1 }}>
          {error}
        </Badge>
      )}
    </div>
  );
};

DynamicField.propTypes = {
  RenderPrint: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  hideLabel: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  containerStyle: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  inputTransform: PropTypes.func,
  sbt: PropTypes.string,
};

DynamicFieldStyle1.propTypes = DynamicField.propTypes;

DynamicField.defaultProps = {
  RenderPrint: null,
  type: "text",
  label: "",
  icon: "",
  options: undefined,
  hideLabel: undefined,
  containerStyle: {},
  inputTransform: (x) => x,
  placeholder: undefined,
  onChange: null,
  value: null,
  sbt: null,
};
