import { useSelector, useDispatch } from "react-redux";
import { get } from "sites-common/utils/lodash";
import { selectorGlobals as S, selectorPersist as SP } from "./state/selectors";
import { setGlobals as A, setPersist as AP } from "./state/actions";

export const useGlobals = (key, initialValue = undefined) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => S(state, key, initialValue));

  return [value, (v) => dispatch(A(key, v))];
};

export const useGlobalsBoolean = (key, initialValue = false) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => S(state, key, initialValue));

  return [
    value,
    {
      set: (v) => dispatch(A(key, !!v)),
      reset: () => dispatch(A(key, initialValue)),
      toggle: () => dispatch(A(key, !value)),
      on: () => dispatch(A(key, true)),
      off: () => dispatch(A(key, false)),
    },
  ];
};

export const selectorGlobals = S;
export const actionGlobals = A;

export const selectorBoolean = S;
export const actionBoolean = A;

export const usePersist = (key, initialValue = undefined) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => SP(state, key, initialValue));

  return [
    value,
    (v) => dispatch(AP(key, v)),
    {
      getDeepKey: (k, d) => get(value, k, d),
      deleteDeepKey: (k) => {
        const newkey = [
          ...(typeof key === "string" ? key.split(".") : key),
          ...(typeof k === "string" ? k.split(".") : k),
        ];
        dispatch(AP(newkey, null, "deleteKey"));
      },
      setDeepKV: (k, v) => {
        const newkey = [
          ...(typeof key === "string" ? key.split(".") : key),
          ...(typeof k === "string" ? k.split(".") : k),
        ];
        dispatch(AP(newkey, v));
      },
    },
  ];
};

export const selectorPersist = SP;
export const actionPersist = AP;
