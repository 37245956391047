import applyProdQaConfig from "sites-common/utils/applyProdQaConfig";

const IndiaId = applyProdQaConfig({
  prod: {
    id: 358,
  },
  qa: {
    id: 358,
  },
}).id;

export default IndiaId;
