const SHOW_PARTICIPANTS_PANEL = "SHOW_PARTICIPANTS_PANEL";
const HIDE_PARTICIPANTS_PANEL = "HIDE_PARTICIPANTS_PANEL";
const RESET_PARTICIPANTS_PANEL = "RESET_PARTICIPANTS_PANEL";

const NEW_PARTICIPANT_PAGE = "NEW_PARTICIPANT_PAGE";
const DISCARD_PARTICIPANT_PAGE = "DISCARD_PARTICIPANT_PAGE";

const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";
const ADD_PARTICIPANT = "ADD_PARTICIPANT";

const PARTICIPANT_VALIDATED = "PARTICIPANT_VALIDATED";
const PARTICIPANT_VALIDATION_IN_PROGRESS = "PARTICIPANT_VALIDATION_IN_PROGRESS";
const PARTICIPANT_VALIDATION_ERROR = "PARTICIPANT_VALIDATION_ERROR";

export const P_TO_VALIDATE = "p-to-validate";
export const P_VALIDATED = "p-validated";
export const P_VALIDATE_ERROR = "p-validate-error";
export const P_VALIDATION_IN_PROGRESS = "p-in-progress";

const SET_PARTICIPANT_ALERT = "SET_PARTICIPANT_ALERT";
const DISMISS_PARTICIPANT_ALERT = "DISMISS_PARTICIPANT_ALERT";

const SUBMIT_PARTICIPANTS = "SUBMIT_PARTICIPANTS";
const RESET_SUBMIT_PARTICIPANTS = "RESET_SUBMIT_PARTICIPANTS";
const SET_P2DATA = "SET_P2DATA";

const defaultStyles = {
  colors: {
    background: "#f4ebd0",
    text: "#122620",
    primary: "#b68d40",
    tertiary: "#d3b680",
    secondary: "#be9851",
    well: "#d6ad60",
    error: "#913831",
    backgroundError: "#d1c9b2",
    warning: "#FF4433",
    backgroundWarning: "#e1d8bf",
  },
};

const initialState = {
  pnr: null,
  uniqref: null,
  persons: [],
  keys: {},
  styles: defaultStyles,
  strings: {},

  numPersons: 0,

  constraints: null,
  p2fieldmap: null,

  pageAddNew: false,
  isOpen: false,

  clientState: {},

  alert: null,

  submit: false,
  p2data: null,
};

const newParticipantObj = {
  name: "",
};

const panelsReducer = (state = initialState, action) => {
  const { colorScheme, ...rest } = action;
  switch (action.type) {
    case SHOW_PARTICIPANTS_PANEL:
      return {
        ...state,
        isOpen: true,
        styles: { colors: { ...defaultStyles.colors, ...action.colorScheme } },
        ...rest,
      };

    case HIDE_PARTICIPANTS_PANEL:
      return {
        ...state,
        isOpen: false,
        pageAddNew: null,
      };

    case RESET_PARTICIPANTS_PANEL:
      return { ...initialState, isOpen: false };

    case NEW_PARTICIPANT_PAGE:
      return {
        ...state,
        pageAddNew: { ...newParticipantObj, child: action.child },
      };

    case DISCARD_PARTICIPANT_PAGE:
      return {
        ...state,
        pageAddNew: null,
      };

    case REMOVE_PARTICIPANT: {
      const persons1 = state.persons.filter((p) => p.key !== action.key);
      const keys1 = {};
      persons1.forEach((person) => {
        keys1[person.key] = state.keys[person.key];
      });

      const s1 = {
        ...state,
        persons: [...persons1],
        keys: { ...keys1 },
        numPersons: persons1.length,
      };
      return s1;
    }

    case ADD_PARTICIPANT:
      if (!(action.key in state.keys)) {
        const persons2 = [
          ...state.persons,
          { ...action.person, key: action.key },
        ];
        const keys2 = { ...state.keys, [action.key]: [P_TO_VALIDATE] };
        return {
          ...state,
          persons: persons2,
          keys: keys2,
          numPersons: persons2.length,
        };
      }
      return state;

    case PARTICIPANT_VALIDATED:
      return {
        ...state,
        keys: { ...state.keys, [action.key]: [P_VALIDATED] },
      };
    case PARTICIPANT_VALIDATION_IN_PROGRESS:
      return {
        ...state,
        keys: { ...state.keys, [action.key]: [P_VALIDATION_IN_PROGRESS] },
      };
    case PARTICIPANT_VALIDATION_ERROR:
      return {
        ...state,
        keys: { ...state.keys, [action.key]: [P_VALIDATE_ERROR, action.error] },
      };

    case SET_PARTICIPANT_ALERT:
      return {
        ...state,
        alert: { title: action.title, message: action.message },
      };

    case DISMISS_PARTICIPANT_ALERT:
      return {
        ...state,
        alert: null,
      };

    case SUBMIT_PARTICIPANTS:
      return {
        ...state,
        p2data: action.p2data,
        submit: true,
      };
    case RESET_SUBMIT_PARTICIPANTS:
      return {
        ...state,
        submit: false,
      };
    case SET_P2DATA:
      return {
        ...state,
        p2data: action.p2data,
      };

    default:
      return state;
  }
};

export default panelsReducer;
