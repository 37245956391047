import { Panel } from "office-ui-fabric-react";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  selectorParticipantsPanel,
  selectorSrcmProfile,
} from "../state/selectors";
import ParticipantsPanelGlobal, {
  DiscardButton,
} from "./ParticipantsPanelGlobal";

const RenderFooterContent = () => <DiscardButton />;

const ParticipantPanel = React.memo(({ Login }) => {
  const participantsPanel = useSelector(selectorParticipantsPanel);
  const srcmProfile = useSelector(selectorSrcmProfile);

  const { styles, pageAddNew, numPersons, pnr } = participantsPanel;

  if (participantsPanel === null || participantsPanel === undefined) {
    return null;
  }

  if (!participantsPanel.isOpen) {
    return null;
  }

  const showFooter = !pageAddNew && numPersons < 1;

  let headerText = "Select Participants to Register";
  if (!srcmProfile) {
    headerText = "Sign In to Register";
  } else if (pnr && pnr !== "NEWPNR") {
    headerText = `Select more participants for PNR: ${pnr}`;
  }

  return (
    <Panel
      isOpen
      isBlocking
      hasCloseButton={false}
      headerText={headerText}
      isFooterAtBottom
      styles={{
        header: !srcmProfile
          ? {
              borderBottom: "solid 1px",
              padding: "10px",
              width: "90%",
              textAlign: "center",
              margin: "auto 20px",
            }
          : { marginBottom: "20px" },
        headerText: {
          fontSize: "1.2em",
        },
        main: {
          color: styles.colors.text,
          backgroundColor: styles.colors.background,
        },
        overlay: { backgroundColor: "rgba(0,0,0, 0.7)" },
      }}
      onRenderFooterContent={showFooter && RenderFooterContent}
    >
      {!!srcmProfile && <ParticipantsPanelGlobal />}
      {!srcmProfile && <Login />}
    </Panel>
  );
});

ParticipantPanel.propTypes = {
  Login: PropTypes.element.isRequired,
};

export default ParticipantPanel;
