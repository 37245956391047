import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
// import 'firebase/firestore' // <- needed if using firestore
import PropTypes from "prop-types";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
// import { createFirestoreInstance } from "redux-firestore";
import { Alert } from "../components/Alert";
import { AuthIsLoaded } from "../components/Login";

const WrapRootElement = ({
  firebaseAuthConfig,
  store,
  children,
  splashScreen,
}) => {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseAuthConfig);
    // firebase.firestore() // <- needed if using firestore
  }
  const rrfConfig = {
    // userProfile: "users",
    // useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  };
  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    // createFirestoreInstance, // <- needed if using firestore
  };
  // useEffect(() => {
  //   store.dispatch(setGlobalConfig(globalConfig));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded loader={splashScreen}>{children}</AuthIsLoaded>
      </ReactReduxFirebaseProvider>
      <Alert />
    </>
  );
};

WrapRootElement.propTypes = {
  firebaseAuthConfig: PropTypes.shape({}).isRequired,
  store: PropTypes.shape({
    dispatch: PropTypes.func,
  }).isRequired,
  splashScreen: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.shape([]).isRequired,
};

WrapRootElement.defaultProps = {
  splashScreen: "splash screen...",
};

export default WrapRootElement;
