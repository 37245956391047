import { displayError } from "../../utils/customErrors";
import profileApiUrl from "./profileApiUrl";
import { mySrcmConfig } from "./common-utils";

const fetchProfileToken = (username, password) => {
  const l_api = "/o/token/";
  const l_method = "POST";
  if (!username || !password) {
    return Promise.reject(Error("Invalid Login Email / Password"));
  }

  const url = profileApiUrl(mySrcmConfig, l_api);
  const postData = {
    client_id: mySrcmConfig.baseClient,
    grant_type: "password",
    username,
    password,
  };
  const searchParams = Object.keys(postData)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(postData[key])}`;
    })
    .join("&");

  const headers = {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };

  const data = {
    method: l_method,
    headers,
    body: searchParams,
  };
  return fetch(url, data)
    .then((res) => {
      if (!res.ok) {
        throw res.statusText;
      }
      return res.json();
    })
    .then((resp) => {
      try {
        if (resp.token_type === "Bearer") {
          if (!resp.access_token) {
            throw new Error("Invalid Response from Network / Profile Server");
          } else {
            return resp.access_token;
          }
        } else {
          throw new Error("Invalid Response from Profile Server");
        }
      } catch (e) {
        displayError(e);
        throw Error("Invalid Response from Profile Service");
      }
    });
};

export default fetchProfileToken;
