import { useSelector } from "react-redux";
import { selectorSrcmProfile } from "../state/selectors";

const useAuth = () => {
  const srcmProfile = useSelector(selectorSrcmProfile);

  return {
    srcmProfile,
    isLoggedIn: !!srcmProfile,
  };
};

export default useAuth;
