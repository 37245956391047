import { selectorJson } from "../selectors";
import { INVALIDATE_URL, RECEIVE_URL } from "../constants";
import requestJson from "./common-utils";

function receiveJson(key, data) {
  return {
    type: RECEIVE_URL,
    key,
    data,
    receivedAt: Date.now(),
  };
}

function invalidateJson(key, error = "Error Fetching URL") {
  return {
    type: INVALIDATE_URL,
    key,
    error,
  };
}

function shouldFetchJson(state, key) {
  const blob = selectorJson(state, key);

  if (blob.isFetching || blob.isLoaded) {
    return false;
  }
  if (blob.errorMessage) {
    return false;
  }
  return true;
}

function fetchJson(key, input, queryParams = {}) {
  return (dispatch) => {
    if (!key) {
      // alert("Invalid Url Key");
      dispatch(invalidateJson(key));
      return;
    }

    dispatch(requestJson(key));

    const url = input.includes("?")
      ? `${input}&${new URLSearchParams(queryParams)}`
      : `${input}?${new URLSearchParams(queryParams)}`;

    fetch(url)
      .then((R) => R.json())
      .then((blob) => {
        if (blob) {
          dispatch(receiveJson(key, blob));
        } else {
          dispatch(invalidateJson(key, "Url Not Found"));
        }
      })
      .catch((e) => {
        dispatch(invalidateJson(key, e.toString()));
      });
  };
}
export default function fetchJsonIfNeeded(
  key,
  input,
  queryParams = {},
  force = false
) {
  return (dispatch, getState) => {
    if (force || shouldFetchJson(getState(), key)) {
      return dispatch(fetchJson(key, input, queryParams));
    }
    return null;
  };
}
