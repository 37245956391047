/** @jsx jsx */
import { jsx } from "theme-ui";

const style1 = {
  padding: "6px 10px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "16px",
  transitionDuration: "0.4s",
  cursor: "pointer",
  borderRadius: "3px",
  backgroundColor: "white",
  color: "black",
  border: "1px solid #247BA0",
  "&:hover": {
    backgroundColor: "#B6DEE2",
  },
};

const Button1 = (props) => <button type="button" {...props} sx={style1} />;
export const Button = Button1;
export default Button1;
