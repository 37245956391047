import { get } from "sites-common/utils/lodash";
import { fetchSrcmAPI } from "sites-common/service-wrappers/profile-api";
import {
  INVALIDATE_SRCM_API_STORE,
  REQUEST_SRCM_API_STORE,
  RECEIVE_SRCM_API_STORE,
} from "../constants";
import { initUrlBlob } from "../reducers";
import { selectorSrcmApiStoreCollectionParams } from "../selectors";

function invalidateSrcmApiStore(key, error = "Error Fetching URL") {
  return {
    type: INVALIDATE_SRCM_API_STORE,
    key,
    error,
  };
}
function requestSrcmApiStore(key) {
  return {
    type: REQUEST_SRCM_API_STORE,
    key,
  };
}

function receiveSrcmApiStore(collection, key, apiParams, data) {
  return {
    type: RECEIVE_SRCM_API_STORE,
    key,
    data,
    collection,
    apiParams,
    receivedAt: Date.now(),
  };
}

function fetchSrcmApiStore(collection, key, apiParams = {}) {
  return (dispatch, getState) => {
    const {
      api,
      method = "GET",
      extraHdrs = {},
      extraData = {},
      forceAuth = false,
      methodParams = {},
      client = "baseClient",
    } = apiParams;

    const state = getState();

    const authToken = get(state, ["persist", "srcmtoken", "token"], "");
    if (!authToken) {
      dispatch(invalidateSrcmApiStore(key, "AuthToken Not Found"));
      return;
    }

    dispatch(requestSrcmApiStore(key, apiParams));
    fetchSrcmAPI(
      api,
      authToken,
      method,
      extraHdrs,
      extraData,
      forceAuth,
      methodParams,
      client
    )
      .then((blob) => {
        if (blob) {
          dispatch(receiveSrcmApiStore(collection, key, apiParams, blob));
        } else {
          dispatch(invalidateSrcmApiStore(key, "Api Error"));
        }
      })
      .catch((e) => {
        dispatch(invalidateSrcmApiStore(key, e.toString()));
      });
  };
}

function shouldFetchSrcmApiStore(state, key) {
  const blob =
    key in state.srcmstore.api_calls
      ? state.srcmstore.api_calls[key]
      : initUrlBlob;

  if (blob.isFetching || blob.isLoaded) {
    return false;
  }
  if (blob.errorMessage) {
    return false;
  }
  return true;
}

export default function fetchSrcmApiStoreIfNeeded(
  collection,
  pageParams = { page: 1, page_size: 100 },
  force = false
) {
  return (dispatch, getState) => {
    const state = getState();

    const apiParams = selectorSrcmApiStoreCollectionParams(state, collection);
    if (!apiParams || !pageParams || !pageParams.page) {
      return null;
    }
    const { api, method = "GET", methodParams = {} } = apiParams;
    const key =
      api +
      (method === "GET" ? method : new Date().valueOf()) +
      JSON.stringify(pageParams) +
      JSON.stringify(methodParams);

    if (force || shouldFetchSrcmApiStore(state, key)) {
      return dispatch(
        fetchSrcmApiStore(collection, key, {
          ...apiParams,
          methodParams: { ...methodParams, ...pageParams },
        })
      );
    }
    return null;
  };
}
