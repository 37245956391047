/** @jsx jsx */
import { jsx } from "theme-ui";
import { useSelector } from "react-redux";
import { selectorSrcmProfile } from "../state/selectors";

export default function ensureSignedInSilent(Component) {
  return function EnhancedComponent(props) {
    const srcmProfile = useSelector(selectorSrcmProfile);

    if (srcmProfile) {
      return <Component {...props} />;
    }

    return null;
  };
}
