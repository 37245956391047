import React, { useCallback } from "react";
import { Select as ThemeUISelect } from "theme-ui";
import PropTypes from "prop-types";

const Select = ({
  value,
  options,
  onChange,
  variant,
  renderPrint,
  ...restProps
}) => {
  const oc = useCallback(
    (e) => {
      if (onChange) {
        const obj = options.find(
          (option) =>
            `${typeof option === "string" ? option : option.name}` ===
            `${e.target.value}`
        );

        if (typeof obj === "string") {
          onChange(obj);
        } else {
          onChange(obj.name, { id: obj.name, name: obj.label });
        }
      }
    },
    [options, onChange]
  );

  if (renderPrint) {
    const option = options.find((o) => o.name === value);
    return renderPrint(option ? option.label : value);
  }

  return (
    <ThemeUISelect
      variant={variant}
      value={value ? `${value}` : ""}
      onChange={oc}
      {...restProps}
    >
      {options.map((option) => {
        const isOptionString = typeof option === "string";
        const theOption = isOptionString ? option : option.name;
        const theLabel = isOptionString ? option : option.label;
        return (
          <option key={`o${theOption}`} value={theOption}>
            {theLabel}
          </option>
        );
      })}
    </ThemeUISelect>
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(["border-0", "border-bottom", "primary"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderPrint: PropTypes.func,
};

Select.defaultProps = {
  onChange: null,
  variant: "border-0",
  value: "",
  renderPrint: null,
};

export default React.memo(Select);
