import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { dismissAlert } from "../state/actions";
import { selectorAlert } from "../state/selectors";
import { Button } from "./Button";
import { Modal } from "./Modal";

const DefaultButton = ({ text, onClick }) => (
  <Button onClick={onClick}>{text}</Button>
);

export const Alert = React.memo(() => {
  const alert = useSelector(selectorAlert);
  const dispatch = useDispatch();

  if (alert === null || alert === undefined) {
    return null;
  }

  if (!alert.message) {
    return null;
  }
  const {
    title = "",
    cancelText = "OK",
    confirmText = null,
    confirmCallback = null,
    discardCallback = () => {},
    isBlocking = false,
    message,
  } = alert;

  const cancelCallback = () => {
    dispatch(dismissAlert());
    discardCallback();
  };
  // console.log("Alert", alert);

  return (
    <Modal handleClose={isBlocking ? null : cancelCallback} title={title}>
      <div style={{ padding: "10px 0" }}>{message}</div>
      <div style={{ float: "right" }}>
        {!!confirmText && !!confirmCallback && (
          <DefaultButton
            onClick={() => {
              cancelCallback();
              confirmCallback();
            }}
            text={confirmText}
          />
        )}
        <DefaultButton onClick={cancelCallback} text={cancelText} />
      </div>
    </Modal>
  );
});

DefaultButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default () => {};
