import React from "react";
import { Alert } from "../components/Alert";

export default ({ children }) => {
  // useEffect(() => {
  //   store.dispatch(setGlobalConfig(globalConfig));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {children}
      <Alert />
    </>
  );
};
