export const RESET_COUNTER = "RESET_COUNTER";
export const INC_COUNTER = "INC_COUNTER";
export const DEC_COUNTER = "DEC_COUNTER";

const initialState = {
  counter: 0,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_COUNTER:
      return {
        ...state,
        counter: 0,
      };

    case INC_COUNTER:
      return {
        ...state,
        counter: state.counter + 1,
      };
    case DEC_COUNTER:
      return {
        ...state,
        counter: state.counter - 1,
      };
    default:
      return state;
  }
};

export default reducer;
