import { useCallback, useReducer } from "react";

function reducer(state, action) {
  switch (action.type) {
    case "true":
      return true;
    case "false":
      return false;
    case "toggle":
      return !state;
    case "set":
      return action.n;
    default:
      throw new Error();
  }
}

function useMyBoolean(d = false) {
  const [s, dispatch] = useReducer(reducer, d);

  const setTrue = useCallback(() => dispatch({ type: "true" }), []);
  const setFalse = useCallback(() => dispatch({ type: "false" }), []);
  const toggle = useCallback(() => dispatch({ type: "toggle" }), []);
  const set = useCallback((n) => dispatch({ type: "set", n }), []);

  return [s, { setTrue, setFalse, toggle, set }];
}
export default useMyBoolean;
