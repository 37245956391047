const _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.onInitialClientRender = exports.wrapRootElement = void 0;

const _react = _interopRequireDefault(require("react"));

const _reactRedux = require("react-redux");

const _constants = require("./constants");

// const { PersistGate } = require ("redux-persist/integration/react");

const { initialPersistStateFn } = require("./state/localStorageFn");

const wrapRootElement = function wrapRootElement(_ref, pluginOptions) {
  let _pluginOptions$window;

  const { element } = _ref;

  if (pluginOptions === void 0) {
    pluginOptions = {};
  }

  const {
    tosUrl = { in: "https://heartfulness.org/in/terms/" },
    privacyPolicyUrl = { in: "https://heartfulness.org/in/privacy-policy/" },
    donationServiceConfig,
    firebaseStoreConfig,
    firebaseAuthConfig,
    mySrcmConfig,
    splashScreen = "loading...",
    firestoreInitQueries,
  } = pluginOptions;

  const enabledFirebase = !!firebaseAuthConfig;
  const enabledFireStore = !!firebaseStoreConfig;

  const globalConfig = {
    tosUrl,
    privacyPolicyUrl,
    mySrcmConfig,
    donationServiceConfig,
    enabledFirebase,
    enabledFireStore,
  };

  const RootComponent = require(enabledFirebase
    ? enabledFireStore
      ? "./wrap-root-element/firestore"
      : "./wrap-root-element/firebase"
    : "./wrap-root-element/onlyredux").default;

  const preloadedState =
    window[
      (_pluginOptions$window = pluginOptions.windowKey) !== null &&
      _pluginOptions$window !== void 0
        ? _pluginOptions$window
        : _constants.DEFAULT_OPTIONS.windowKey
    ];

  const _createStore = _interopRequireDefault(
    require("./.tmp362481/createStore")
  );
  const { store /* persistor */ } = (0, _createStore.default)({
    ...preloadedState,
    global: { globalConfig },
    persist: initialPersistStateFn(),
  });

  const childPersist = _react.default.createElement(
    RootComponent,
    {
      store,
      firebaseStoreConfig,
      firebaseAuthConfig,
      globalConfig,
      splashScreen,
      firestoreInitQueries,
    },
    element
  );

  return /* #__PURE__ */ _react.default.createElement(
    _reactRedux.Provider,
    { store },
    //      _react.default.createElement( PersistGate, {loading:"loading storage", persistor},
    childPersist
    //      )
  );
};

exports.wrapRootElement = wrapRootElement;

const onInitialClientRender = function onInitialClientRender(_, pluginOptions) {
  let _pluginOptions$cleanu;

  if (pluginOptions === void 0) {
    pluginOptions = {};
  }

  const shouldCleanup = Boolean(
    (_pluginOptions$cleanu = pluginOptions.cleanupOnClient) !== null &&
      _pluginOptions$cleanu !== void 0
      ? _pluginOptions$cleanu
      : _constants.DEFAULT_OPTIONS.cleanupOnClient
  );

  if (shouldCleanup) {
    let _pluginOptions$window2;

    const windowKey =
      (_pluginOptions$window2 = pluginOptions.windowKey) !== null &&
      _pluginOptions$window2 !== void 0
        ? _pluginOptions$window2
        : _constants.DEFAULT_OPTIONS.windowKey;
    delete window[windowKey];
    const preloadedStateEl = document.getElementById(
      _constants.SCRIPT_ELEMENT_ID
    );

    if (preloadedStateEl) {
      preloadedStateEl.parentNode.removeChild(preloadedStateEl);
    }
  }
};

exports.onInitialClientRender = onInitialClientRender;
