import React from "react";
import { capitalize } from "sites-common/utils/lodash";
import IndiaId from "../PnrSummary/AshramStays/config";

const age_group_options = [
  { name: 4, label: "0-4" },
  { name: 9, label: "5-9" },
  { name: 14, label: "10-14" },
  { name: 17, label: "15-17" },
  { name: 24, label: "18-24" },
  { name: 29, label: "25-29" },
  { name: 35, label: "30-35" },
  { name: 39, label: "36-39" },
  { name: 44, label: "40-44" },
  { name: 49, label: "45-49" },
  { name: 54, label: "50-54" },
  { name: 59, label: "55-59" },
  { name: 64, label: "60-64" },
  { name: 69, label: "65-69" },
  { name: 74, label: "70-74" },
  { name: 79, label: "75-79" },
  { name: 84, label: "80-84" },
  { name: 89, label: "85-89" },
  { name: 94, label: "90-94" },
  { name: 99, label: "94-99" },
];

export const isIndian = (country) =>
  typeof country === "string"
    ? country.toLowerCase() === "india"
    : country === IndiaId;

export const additionalFields = [
  {
    name: "citizen_of_country",
    label: "Citizenship",
    type: "srcm_country_id",
  },
  {
    name: "has_oci",
    label: "Overseas Citizen of India",
    type: "toggle",
    defaultValue: false,
    dynamic: (r) => {
      const currentUser = r.citizen_of_country;
      const check = currentUser ? isIndian(currentUser) : true;
      return check
        ? { props: { required: false, readOnly: true }, hide: true }
        : {
            props: {
              required: true,
              readOnly: false,
            },
          };
    },
  },
];

export const govFields = [
  {
    name: "gov_id_type",
    label: "Select ID Proof",
    type: "select",
    options: [
      "aadhar",
      "pan_card",
      "voter_id",
      "driving_license",
      "passport",
    ].map((x) => ({
      name: x,
      label: capitalize(x.replace("_", " ")),
    })),
  },
  {
    name: "gov_id",
    label: "Enter ID Proof Number",
    type: "text",
    dynamic: (r) => {
      if (r?.gov_id_type) {
        return { props: { required: true }, hide: false };
      }
      return { props: { required: false }, hide: true };
    },
  },
];

export const alternateNumber = [
  {
    name: "alternate_phone",
    label: "Alternate Mobile No",
    type: "phone",
    props: {
      required: true,
      description:
        "Please enter country code along with mobile number. example +918888888888",
    },
  },
];

const personFormFields = [
  {
    name: "name",
    label: "Full Name",
    type: "text",
  },
  {
    name: "city_id",
    label: "City / State / Country",
    type: "city",
  },
  {
    name: "age_group",
    label: "Age Group",
    type: "select",
    options: age_group_options,
  },
  {
    name: "gender",
    label: "Gender",
    type: "select",
    options: [
      { name: "M", label: "Male", value: "M" },
      { name: "F", label: "Female", value: "F" },
      { name: "U", label: "Unspecified", value: "U" },
    ],
  },
  {
    name: "mobile",
    label: "Mobile",
    type: "phone",
    props: {
      required: false,
      description:
        "Please enter country code along with mobile number. example +918888888888",
    },
  },
  {
    name: "email",
    label: "E-Mail",
    type: "email",
    props: {
      required: false,
    },
  },
  {
    name: "citizen_of_country",
    label: "Citizenship",
    type: "srcm_country_id",
  },
  {
    name: "has_oci",
    label: "Overseas Citizen of India",
    type: "toggle",
    defaultValue: false,
    dynamic: (r) => {
      const currentUser = r.citizen_of_country;
      const check = currentUser ? isIndian(currentUser) : true;
      return check
        ? { props: { required: false }, hide: true }
        : {
            props: {
              required: true,
              description: (
                <div
                  style={{
                    color: "rgb(96, 94, 92)",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    backgroundColor: "beige",
                  }}
                >
                  Overseas visitors are required to submit Form-C, copies of
                  passport and visa upon arrival, as per Indian Government
                  regulations. Exemptions:&nbsp;
                  <a
                    href="https://www.mea.gov.in/Portal/CountryQuickLink/703_PIO-OCI.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Indian passports or OCI cards or PIO cards
                  </a>
                  .
                </div>
              ),
            },
          };
    },
  },
  {
    name: "acknowledgement",
    label:
      "I have taken the consent of the participants before registering and the information submitted is accurate to the best of my knowledge.",
    type: "checkbox",
    props: {
      className: "checkbox_custom",
    },
  },
];

export default personFormFields;
