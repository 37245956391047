const defaultConfig =
  require("./src/components/BaseComponents/AppConfig").default;



const appConfigRoutes = {
  "/events-menu/": {
    config: require("./src/components/HomeComponents/AppConfig").default,
  },
  "/my-events/": {
    config: require("./src/crud/my-events/AppConfig").default,
  },
  "/events-tracker/": {
    config: require("./src/crud/events-tracker/AppConfig").default,
  },
  "/approving-events/": {
    config: require("./src/crud/approving-events/AppConfig").default,
  },
  "/events-heartsappadmin/": {
    config: require("./src/crud/events-heartsappadmin/AppConfig").default,
  },
};

module.exports = appConfigRoutes;
