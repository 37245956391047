// import personFormFields from "./personFormFields";

function strAgeGroup(age) {
  const a = (b1) => b1 + (4 - (b1 % 5));

  if (typeof age !== "number") {
    if (typeof age === "string") {
      try {
        const s = parseInt(age, 10);
        if (s) {
          const bb = a(s);
          return `${bb - 4}-${bb}`;
        }
      } catch (e) {
        //
      }
      return age.replaceAll(" ", "");
    }
    return age;
  }

  if (age < 0 || age > 100) {
    return "Invalid";
  }
  const b = a(age);
  return `${b - 4}-${b}`;
}

export default strAgeGroup;
