import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"; // <- needed if using firestore
import "firebase/storage";
import "firebase/functions";
import PropTypes from "prop-types";

import { ReactReduxFirebaseProvider, useFirestore } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { Alert } from "../components/Alert";
import { AuthIsLoaded } from "../components/Login";

const InitFireStoreElement = ({ firestoreInitQueries }) => {
  const firestore = useFirestore();

  useEffect(() => {
    // console.log("firestoreInitQueries", firestoreInitQueries);
    if (firestoreInitQueries) {
      firestore.setListeners(firestoreInitQueries);
      return () =>
        function cleanup() {
          firestore.unsetListeners(firestoreInitQueries);
        };
    }
    return true;
  }, [firestore, firestoreInitQueries]);
  return null;
};

InitFireStoreElement.propTypes = {
  firestoreInitQueries: PropTypes.shape([]),
};

InitFireStoreElement.defaultProps = {
  firestoreInitQueries: undefined,
};

const WrapRootElement = ({
  firebaseStoreConfig,
  firebaseAuthConfig,
  store,
  children,
  splashScreen,
  firestoreInitQueries,
}) => {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseStoreConfig);
    firebase.initializeApp(firebaseAuthConfig, "auth");
    firebase.firestore(); // <- needed if using firestore
    firebase.functions();
  }
  const rrfConfig = {
    useFirestoreForStorageMeta: true,
    // userProfile: "users",
    // useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  };
  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance, // <- needed if using firestore
  };
  // useEffect(() => {
  //   store.dispatch(setGlobalConfig( globalConfig));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const { onLoaded, onSignedIn } = firestoreInitQueries;

  return (
    <>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <InitFireStoreElement key="1" firestoreInitQueries={onLoaded} />
        <AuthIsLoaded loader={splashScreen}>
          <InitFireStoreElement key="2" firestoreInitQueries={onSignedIn} />
          {children}
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
      <Alert />
    </>
  );
};
WrapRootElement.propTypes = {
  firebaseAuthConfig: PropTypes.shape({}).isRequired,
  firebaseStoreConfig: PropTypes.shape({}).isRequired,
  firestoreInitQueries: PropTypes.shape({
    onSignedIn: PropTypes.shape([]),
    onLoaded: PropTypes.shape([]),
  }),
  store: PropTypes.shape({
    dispatch: PropTypes.func,
  }).isRequired,
  splashScreen: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.shape([]).isRequired,
};

WrapRootElement.defaultProps = {
  splashScreen: "splash screen...",
  firestoreInitQueries: { onSignedIn: null, onLoaded: null },
};

export default WrapRootElement;
