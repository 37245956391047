/** @jsx jsx */
import { jsx } from "theme-ui";

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectorParticipantsPanel } from "../../state/selectors";
import {
  discardParticipantPage,
  // removeParticipant,
} from "../../state/actions/participantsPanel";
import ChildForm from "./ChildForm";

const AddChild = () => {
  const { styles } = useSelector(selectorParticipantsPanel);
  const dispatch = useDispatch();

  const onDiscard = useCallback(
    () => dispatch(discardParticipantPage()),
    [dispatch]
  );

  return (
    <div sx={{ bg: styles.colors.well, p: 2 }}>
      <ChildForm onDiscard={onDiscard} />
    </div>
  );
};

export default AddChild;
