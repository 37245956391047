function defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

let xprism;

const heading = {
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
};
const index = {
  useBodyStyles: false,
  colors: {
    boxShadow: "rgb(250 255 255 / 22%)",
    text: "#fff",
    background: "#060606",
    primary: "#3cf",
    secondary: "#e0f",
    // muted: "#191919",
    muted: "#4c4c4c",
    highlight: "#29112c",
    gray: "#999",
    purple: "#c0f",
    danger: "rgb(236, 52, 52)",
    success: "#28a745",
  },
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  fontWeights: {
    body: 400,
    heading: 700,
    display: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  textStyles: {
    heading,
    display: {
      variant: "textStyles.heading",
      fontSize: [5, 6],
      fontWeight: "display",
      letterSpacing: "-0.03em",
      mt: 3,
    },
  },
  styles: {
    Container: {
      p: 3,
      maxWidth: 1024,
    },
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      variant: "textStyles.display",
    },
    h2: {
      variant: "textStyles.heading",
      fontSize: 5,
    },
    h3: {
      variant: "textStyles.heading",
      fontSize: 4,
    },
    h4: {
      variant: "textStyles.heading",
      fontSize: 3,
    },
    h5: {
      variant: "textStyles.heading",
      fontSize: 2,
    },
    h6: {
      variant: "textStyles.heading",
      fontSize: 1,
    },
    a: {
      color: "primary",
      "&:hover": {
        color: "secondary",
      },
    },
    pre: {
      variant: "prism",
      fontFamily: "monospace",
      fontSize: 1,
      p: 3,
      color: "text",
      bg: "muted",
      overflow: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      color: "secondary",
      fontSize: 1,
    },
    inlineCode: {
      fontFamily: "monospace",
      color: "secondary",
      bg: "muted",
    },
    table: defineProperty(
      {
        width: "100%",
        my: 4,
        borderCollapse: "separate",
        borderSpacing: 0,
      },
      ["th", "td"],
      {
        textAlign: "left",
        py: "4px",
        pr: "4px",
        pl: 0,
        borderColor: "muted",
        borderBottomStyle: "solid",
      }
    ),
    th: {
      verticalAlign: "bottom",
      borderBottomWidth: "2px",
    },
    td: {
      verticalAlign: "top",
      borderBottomWidth: "1px",
    },
    hr: {
      border: 0,
      borderBottom: "1px solid",
      borderColor: "muted",
    },
    img: {
      maxWidth: "100%",
    },
  },
  prism:
    ((xprism = {}),
    defineProperty(
      xprism,
      [
        ".comment",
        ".prolog",
        ".doctype",
        ".cdata",
        ".punctuation",
        ".operator",
        ".entity",
        ".url",
      ],
      {
        color: "gray",
      }
    ),
    defineProperty(xprism, ".comment", {
      fontStyle: "italic",
    }),
    defineProperty(
      xprism,
      [
        ".property",
        ".tag",
        ".boolean",
        ".number",
        ".constant",
        ".symbol",
        ".deleted",
        ".function",
        ".class-name",
        ".regex",
        ".important",
        ".variable",
      ],
      {
        color: "purple",
      }
    ),
    defineProperty(xprism, [".atrule", ".attr-value", ".keyword"], {
      color: "primary",
    }),
    defineProperty(
      xprism,
      [".selector", ".attr-name", ".string", ".char", ".builtin", ".inserted"],
      {
        color: "secondary",
      }
    ),
    xprism),
};

module.exports = index;
// # sourceMappingURL=index.js.map
