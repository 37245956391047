import {
  RECEIVE_URL_GLOBAL_RESULT,
  RECEIVE_URL_GLOBAL_SAME_STAY,
  RECEIVE_URL_GLOBAL_STAY_RESULT,
  RECEIVE_URL_RESULT,
  RECEIVE_URL_SAME_STAY,
  RECEIVE_URL_STAY_RESULT,
} from "../constants";

export default function receiveSrcmResultDoc(
  key,
  docId,
  docData,
  searchType = ""
) {
  return {
    type: RECEIVE_URL_RESULT,
    key,
    docId,
    docData,
    receivedAt: Date.now(),
    searchType,
  };
}
export function recieveSrcmSameStay(key, same_stay) {
  return {
    type: RECEIVE_URL_SAME_STAY,
    key,
    same_stay,
    receivedAt: Date.now(),
  };
}
export function receiveSrcmStayResultDoc(key, docId, docData) {
  return {
    type: RECEIVE_URL_STAY_RESULT,
    key,
    docId,
    docData,
    receivedAt: Date.now(),
  };
}
export function receiveSrcmGResultDoc(key, docId, docData) {
  return {
    type: RECEIVE_URL_GLOBAL_RESULT,
    key,
    docId,
    docData,
    receivedAt: Date.now(),
  };
}
export function receiveSrcmGSameStayDoc(key, same_stay) {
  return {
    type: RECEIVE_URL_GLOBAL_SAME_STAY,
    key,
    same_stay,
    receivedAt: Date.now(),
  };
}

export function receiveSrcmGStayResultDoc(key, docId, docData) {
  return {
    type: RECEIVE_URL_GLOBAL_STAY_RESULT,
    key,
    docId,
    docData,
    receivedAt: Date.now(),
  };
}
