import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  profileApiUrl,
  fetchSrcmAPI,
  fetchSrcmAPIDecompose,
} from "../../../service-wrappers/profile-api";
import ServerAutoSuggest from "../ServerAutoSuggest";
import { compact } from "../../../utils/lodash";

function getConfigs({ dflt = [], includeOnly, sbt }) {
  const apiParams = () =>
    fetchSrcmAPIDecompose(`/api/v2/myprofile/preceptors/`, sbt)[1];

  const config = {
    dflt: includeOnly ? dflt.filter(includeOnly) : dflt,
    fetch: (id) => {
      if (dflt.filter((r) => r.id === id).length > 0) {
        const myPromise = new Promise((resolve) => {
          setTimeout(() => {
            resolve(dflt.filter((r) => r.id === id)[0]);
          }, 300);
        });
        return myPromise;
      }
      return fetchSrcmAPI(`/api/v2/myprofile/preceptors/${id}/`, sbt);
    },
    // Trigger suggestions
    getSuggestionValue: (suggestion) =>
      compact([suggestion.name, suggestion?.city]).join(", "),
    // Render Each Option
    renderSuggestion: (suggestion) => `${suggestion.name}, ${suggestion.city}`,
    api: (itrimValue) =>
      profileApiUrl(
        "myprofile-preceptors",
        "name__istartswith",
        itrimValue.toLowerCase().trim()
      ),
    apiParams,
    data2results: (data, itrimValue, getSuggestionValue, dflt1) => {
      const dfltIds = dflt1.map((r) => r.id);
      const filterFn = (f, itrimValue1) =>
        getSuggestionValue(f).toLowerCase().startsWith(itrimValue1);
      const results = [
        ...dflt1.filter((f) => filterFn(f, itrimValue)),
        ...data.results.filter(
          (f) =>
            (!includeOnly || includeOnly(f)) &&
            filterFn(f, itrimValue) &&
            !dfltIds.includes(f.id)
        ),
      ];
      return results;
    },
  };

  return config;
}

const Preceptor = ({ includeOnly, includeDflt, sbt, ...restprops }) => {
  return (
    <ServerAutoSuggest
      {...restprops}
      config={useMemo(
        () => getConfigs({ includeOnly, dflt: includeDflt, sbt }),
        [includeOnly, includeDflt, sbt]
      )}
    />
  );
};

Preceptor.propTypes = {
  includeOnly: PropTypes.func,
  sbt: PropTypes.string.isRequired,
  includeDflt: PropTypes.shape([]),
};
Preceptor.defaultProps = {
  includeOnly: null,
  includeDflt: [],
};

export default Preceptor;
