export const NEWPNR = "NEWPNR";

export const showParticipantsPanel = ({
  uniqref,
  colorScheme,
  constraints,
  p2fieldmap,
  pnr,
  ...rest
}) => ({
  type: "SHOW_PARTICIPANTS_PANEL",
  uniqref,
  colorScheme,
  constraints,
  p2fieldmap,
  pnr,
  ...rest,
});

export const hideParticipantsPanel = () => ({
  type: "HIDE_PARTICIPANTS_PANEL",
});

export const resetParticipantsPanel = () => ({
  type: "RESET_PARTICIPANTS_PANEL",
});

export const newParticipantPage = (child = false) => ({
  type: "NEW_PARTICIPANT_PAGE",
  child,
});

export const removeParticipant = (key) => ({
  type: "REMOVE_PARTICIPANT",
  key,
});

export const person2key = (person) => `${person.id}`;

export const addParticipant = (person) => ({
  type: "ADD_PARTICIPANT",
  person,
  key: person2key(person),
});

export const validatedParticipant = (key) => ({
  type: "PARTICIPANT_VALIDATED",
  key,
});

export const inProgressParticipantValidation = (key) => ({
  type: "PARTICIPANT_VALIDATION_IN_PROGRESS",
  key,
});

export const errorParticipantValidation = (key, error) => ({
  type: "PARTICIPANT_VALIDATION_ERROR",
  key,
  error,
});

export const discardParticipantPage = () => ({
  type: "DISCARD_PARTICIPANT_PAGE",
});

export const setParticipantAlert = (title, message) => ({
  type: "SET_PARTICIPANT_ALERT",
  title,
  message,
});

export const dismissParticipantAlert = () => ({
  type: "DISMISS_PARTICIPANT_ALERT",
});

export const submitParticipants = (p2data = null) => ({
  type: "SUBMIT_PARTICIPANTS",
  p2data,
});

export const resetSubmitParticipants = () => ({
  type: "RESET_SUBMIT_PARTICIPANTS",
});

export const setP2Data = (p2data) => ({
  type: "SET_P2DATA",
  p2data,
});
