import SiteLogo from "../imgs/heartfulness-logo.svg";
import LoginBg from "../imgs/login-bg.png";
import LoginBgWhite from "../imgs/login-bg-box.svg";
import EventLanding from "../imgs/icon-event.svg";
import ApprovalLanding from "../imgs/icon-approval.svg";
import FinanceLanding from "../imgs/icon-finance.svg";
import EventSm from "../imgs/event-black-icon.svg";
import ApprovalSm from "../imgs/approval-black-icon.svg";
import FinanceSm from "../imgs/finance-black-icon.svg";
import MoreVert from "../imgs/more-vert-black.svg";
import MoreVert2 from "../imgs/more-vert-black2.svg";
import CrossDelete from "../imgs/cross-delete.svg";
import GoogleCalendar from "../imgs/Google_Calendar.png";
import Mac from "../imgs/mac.png";
import OutlookCalendar from "../imgs/outlook-calendar.png";

export default {
  logo: SiteLogo,
  event: EventSm,
  approval: ApprovalSm,
  finance: FinanceSm,
  login_bg: LoginBg,
  login_bg_white: LoginBgWhite,
  event_landing: EventLanding,
  approval_landing: ApprovalLanding,
  finance_landing: FinanceLanding,
  more_dot: MoreVert,
  more_dot2: MoreVert2,
  cross_delete: CrossDelete,
  googleCalendar: GoogleCalendar,
  mac: Mac,
  outlookCalendar: OutlookCalendar,
};
