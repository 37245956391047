exports.__esModule = true;
exports.DEFAULT_OPTIONS = exports.SCRIPT_ELEMENT_ID = void 0;
const SCRIPT_ELEMENT_ID = "redux-ssr";
exports.SCRIPT_ELEMENT_ID = SCRIPT_ELEMENT_ID;
const DEFAULT_OPTIONS = {
  serialize: {
    space: 0,
    isJSON: true,
    unsafe: false,
    ignoreFunction: true,
  },
  cleanupOnClient: true,
  windowKey: "__PRELOADED_STATE__",
};
exports.DEFAULT_OPTIONS = DEFAULT_OPTIONS;
