import adaptTheme from "sites-common/gatsby-utils/adaptTheme";
import { base } from "./base-theme";
import { bootstrap } from "./bootstrap-theme";
import dark from "./dark-theme";
import system from "./system-theme";

export const PresetThemes = {
  base,
  bootstrap,
  dark,
  system,
};

Object.keys(PresetThemes).forEach((themeObj) => {
  PresetThemes[themeObj] = adaptTheme(PresetThemes[themeObj]);
});

export default PresetThemes;
