/** @jsx jsx */
import { jsx, Message, Text } from "theme-ui";
import { useSelector } from "react-redux";
import {
  ActionButton,
  CompoundButton,
  DefaultButton,
  Icon,
  IconButton,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";
import PropTypes from "prop-types";
import { get } from "sites-common/utils/lodash";
import { Link } from "gatsby";
import { selectorParticipantsPanel } from "../../state/selectors";

export const HeroButton = (props) => {
  const { styles } = useSelector(selectorParticipantsPanel);

  return (
    <DefaultButton
      styles={{
        root: {
          textAlign: "left",
          borderRadius: 2,
          height: "3.6em",
          minWidth: "197px",
          fontSize: "1.12em",
          backgroundColor: styles.colors.primary,
        },
        rootDisabled: { backgroundColor: "transparent" },
        rootHovered: { backgroundColor: styles.colors.tertiary },
      }}
      {...props}
    />
  );
};

export const InternalButton = (props) => {
  const { styles } = useSelector(selectorParticipantsPanel);
  return (
    <Link
      sx={{
        borderRadius: 2,
        backgroundColor: styles.colors.secondary,
        color: styles.colors.text,
        my: 3,
        display: "inline-block",
        textDecoration: "none",
        padding: "0.375rem 1rem",
        "&:hover": {
          backgroundColor: styles.colors.tertiary,
          color: `${styles.colors.text} !important`,
        },
      }}
      {...props}
    />
  );
};

export const PrimaryButton = (props) => {
  const { styles } = useSelector(selectorParticipantsPanel);

  return (
    <DefaultButton
      styles={{
        root: { borderRadius: 2, backgroundColor: styles.colors.primary },
        rootDisabled: {
          backgroundColor: "transparent",
          color: styles.colors.tertiary,
          borderColor: styles.colors.primary,
        },
        rootHovered: { backgroundColor: styles.colors.tertiary },
      }}
      {...props}
    />
  );
};

export const LowFiButton = (props) => {
  const { styles } = useSelector(selectorParticipantsPanel);

  return (
    <DefaultButton
      styles={{
        root: { borderRadius: 2, color: styles.colors.primary },
        rootHovered: { color: styles.colors.secondary },
      }}
      {...props}
    />
  );
};

export const ContrastButton = (props) => {
  const { styles } = useSelector(selectorParticipantsPanel);

  return (
    <DefaultButton
      styles={{
        root: {
          borderRadius: 2,
          backgroundColor: styles.colors.tertiary,
          color: styles.colors.text,
        },
        rootHovered: {
          backgroundColor: styles.colors.primary,
          color: styles.colors.text,
        },
      }}
      {...props}
    />
  );
};

export const SecondaryButton = (props) => {
  const { styles } = useSelector(selectorParticipantsPanel);

  return (
    <DefaultButton
      styles={{
        root: { borderRadius: 2, backgroundColor: styles.colors.secondary },
        rootDisabled: { backgroundColor: "transparent" },
        rootHovered: { backgroundColor: styles.colors.tertiary },
      }}
      {...props}
    />
  );
};

export const TransparentButton = (props) => {
  const { styles } = useSelector(selectorParticipantsPanel);

  return (
    <DefaultButton
      styles={{
        root: { borderRadius: 2, backgroundColor: "transparent" },
        rootDisabled: { backgroundColor: "transparent" },
        rootHovered: { backgroundColor: styles.colors.tertiary },
      }}
      {...props}
    />
  );
};

export const LinkButton = (props) => {
  const { styles } = useSelector(selectorParticipantsPanel);

  return (
    <ActionButton
      styles={{
        root: { color: styles.colors.primary },
        rootDisabled: { color: styles.colors.combi },
        rootHovered: { color: styles.colors.tertiary },
      }}
      {...props}
    />
  );
};

export const IkonButton = (props) => {
  const { styles } = useSelector(selectorParticipantsPanel);

  return (
    <IconButton
      styles={{
        root: { color: styles.colors.primary },
        rootDisabled: { color: styles.colors.background },
        rootHovered: {
          border: "solid 1px",
          borderColor: styles.colors.primary,
          color: styles.colors.secondary,
          background: styles.colors.background,
        },
      }}
      {...props}
    />
  );
};

export const IkonTextButton = ({ iconName, text, ...restprops }) => {
  const { styles } = useSelector(selectorParticipantsPanel);
  return (
    <CompoundButton
      styles={{
        root: {
          minHeight: "50px",
          color: styles.colors.primary,
          background: "transparent",
          border: "none",
        },
        rootDisabled: { color: styles.colors.tertiary },
        rootHovered: {
          color: styles.colors.secondary,
          background: styles.colors.tertiary,
        },
      }}
      {...restprops}
      text={
        <div sx={{ textAlign: "center" }}>
          <Icon iconName={iconName} />
        </div>
      }
      secondaryText={text}
    />
  );
};

IkonTextButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export const Notice = ({ type, icon, mode, sx, ...props }) => {
  const { styles } = useSelector(selectorParticipantsPanel);
  const colorProps = { ...sx };
  if (type === "error") {
    colorProps.color = styles.colors.error;
    colorProps.backgroundColor = styles.colors.backgroundError;
  }
  if (type === "warning") {
    colorProps.color = styles.colors.warning;
    colorProps.backgroundColor = styles.colors.backgroundWarning;
  }

  let messageBarType = MessageBarType.info;
  if (type === "error") {
    messageBarType = MessageBarType.error;
  }
  if (type === "warning") {
    messageBarType = MessageBarType.warning;
  }
  const messageBarIconProps = icon ? { iconName: "Clock" } : undefined;

  if (mode === "multiline") {
    return <Message {...props} sx={colorProps} />;
  }

  return (
    <MessageBar
      {...props}
      sx={colorProps}
      messageBarIconProps={messageBarIconProps}
      messageBarType={messageBarType}
    />
  );
};
Notice.propTypes = {
  type: PropTypes.string,
  mode: PropTypes.oneOf(["multiline"]),
  icon: PropTypes.string,
  sx: PropTypes.shape({}),
};
Notice.defaultProps = {
  type: undefined,
  icon: undefined,
  mode: undefined,
  sx: undefined,
};

export const BadgeLabel = ({ fg, bg, sx, ...props }) => {
  const { styles } = useSelector(selectorParticipantsPanel);

  const mystyle = {
    whiteSpace: "nowrap",
    p: "0.15em 0.4em",
    fontSize: "0.75em",
    fontWeight: 700,
    borderRadius: "0.3rem",
    ...sx,
    color: get(styles.colors, fg, fg),
    backgroundColor: get(styles.colors, bg, bg),
  };

  return <Text {...props} sx={mystyle} />;
};

BadgeLabel.propTypes = {
  sx: PropTypes.shape({}),
  bg: PropTypes.string,
  fg: PropTypes.string,
};
BadgeLabel.defaultProps = {
  sx: {},
  bg: "primary",
  fg: "background",
};
