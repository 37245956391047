import { noop } from "../lodash";

const prod = process.env.GATSBY_ENV === "prod";

export const displayWarn = console.warn;

export const displayError = console.error;

export const displayLog = !prod ? console.log : noop;
export const displayAlert = !prod ? console.log : noop;

const customErrors = {
  displayWarn,
  displayError,
  displayLog,
  displayAlert,
};

export default customErrors;
