/** @jsx jsx */

import { jsx, Text } from "theme-ui";
import { useState, useCallback } from "react";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
// import { selectorParticipantsPanel } from "../../state/selectors";
import {
  addParticipant,
  discardParticipantPage,
} from "../../state/actions/participantsPanel";
import childFormFields from "./childFormFields";
import { PrimaryButton, TransparentButton } from "./StyledButtons";
import useAuth from "../../auth/useAuth";

const fields = childFormFields;

const validateForm = (r) => {
  if (!!r && !!r.mobile) {
    if (!r.mobile.startsWith("+")) {
      return "Mobile number needs to start with +(country code)";
    }
  }

  if (!!r && !r?.acknowledgement) {
    return "Kindly provide the consent before proceeding.";
  }

  if (!!r && !r.mobile && !r.email) {
    return "One of Parent's email or mobile is mandatory";
  }
  return false;
};
function ChildForm({ onDiscard }) {
  //  const { keys } = useSelector(selectorParticipantsPanel);
  const dispatch = useDispatch();
  const { srcmProfile } = useAuth();
  const [data] = useState({
    city_id: srcmProfile?.city_id,
    email: srcmProfile?.email,
    mobile: srcmProfile?.phone,
  });

  const onSubmit = useCallback(
    (vals, onS) => {
      const newrec = {
        ...vals,
        id: uuidv4(),
        ref: "B99999998",
        q: "child",
      };

      Promise.resolve(newrec).then((v) => {
        dispatch(addParticipant(v));
        dispatch(discardParticipantPage());
        onS();
      });
    },
    [dispatch]
  );

  return (
    <div sx={{ py: 2 }}>
      <Text sx={{ fontWeight: 700, fontSize: "1.2em" }}> Child Details </Text>
      <DynamicForm
        newFormLayout={undefined}
        defaultValues={data}
        formFields={fields}
        formValidator={validateForm}
        formClassName="person_form_custom"
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        buttons={{
          submitButton: (
            <PrimaryButton
              type="submit"
              iconProps={{ iconName: "Save" }}
              text="Save"
            />
          ),
          discardButton: (
            <TransparentButton
              iconProps={{ iconName: "Cancel" }}
              text="Discard"
            />
          ),
        }}
      />
    </div>
  );
}

ChildForm.propTypes = {
  onDiscard: PropTypes.func.isRequired,
};

export default ChildForm;
