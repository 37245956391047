/** @jsx jsx */
// import { jsx, ThemeProvider } from "theme-ui";
import { jsx } from "theme-ui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import PropTypes from "prop-types";
import { DefaultButton } from "office-ui-fabric-react";
import { selectorGlobalConfig, selectorSrcmProfile } from "../state/selectors";
import unsetUser from "../state/actions/unsetUser";
import { useGlobals } from "../globals";
import { Modal } from "../components/Modal";

const SignOutButton = ({ btn, btnText }) => {
  const srcmProfile = useSelector(selectorSrcmProfile);
  const [isFbBlocked] = useGlobals("fb-blocked", true);
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const { enabledFireStore } = useSelector(selectorGlobalConfig);
  const [show, setShow] = useState(false);

  const doSignOut = () => {
    if (isFbBlocked) {
      dispatch(unsetUser());
    } else {
      firebase.logout();
      if (enabledFireStore) {
        firebase.app("auth").auth().signOut();
      }
    }
  };

  const btnStyle = {
    color: "black",
    border: "1px solid rgb(138, 136, 134)",
    fontWeight: "400",
    padding: "3px 20px",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  };

  if (srcmProfile) {
    return btn ? (
      <div onClick={doSignOut} onKeyDown={doSignOut} tabIndex={0} role="button">
        {btn}
      </div>
    ) : (
      <div>
        <span
          sx={{
            ...btnStyle,
            minWidth: "80px",
            display: "inline-block",
          }}
          onClick={() => setShow(true)}
          onKeyDown={() => setShow(true)}
          tabIndex={0}
          role="button"
        >
          {btnText}
        </span>
        {show && (
          <Modal handleClose={setShow} showCloseBtn={false} position="default">
            <p sx={{ color: "black", textAlign: "center", fontSize: "16px" }}>
              All the saved information will be cleared from this device.
            </p>
            <div
              sx={{
                display: "flex",
                justifyContent: "space-around",
                paddingBottom: "8px",
              }}
            >
              <span
                sx={{
                  ...btnStyle,
                  display: "flex",
                }}
                onClick={() => setShow(false)}
                onKeyDown={() => setShow(false)}
                tabIndex={0}
                role="button"
              >
                Cancel
              </span>
              <DefaultButton onClick={doSignOut} text={btnText} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
  return null;
};
SignOutButton.propTypes = {
  btn: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  btnText: PropTypes.string,
};
SignOutButton.defaultProps = {
  btn: null,
  btnText: "Sign Out",
};

export default SignOutButton;
