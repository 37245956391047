import auth from "./useAuth";
import Login from "./Login";
import SilentSignedInSection from "./SilentSignedInSection";
import NonSignedInOnlySection from "./NonSignedInOnlySection";
import SignInButton from "./SignInButton";
import SignOutButton from "./SignOutButton";
import ensureSignedInSilent from "./ensureSignedInSilent";
import ensureSignedIn from "./ensureSignedIn";
import ensureSignedInConditional from "./ensureSignedInConditional";
import Avatar from "./Avatar";
import SignedInSection from "./SignedInSection";

export {
  // useAuth,
  Login,
  SignedInSection,
  SilentSignedInSection,
  NonSignedInOnlySection,
  SignInButton,
  SignOutButton,
  ensureSignedInSilent,
  ensureSignedIn,
  ensureSignedInConditional,
  Avatar,
};

export default auth;
