import { fetchSrcmAPI } from "sites-common/service-wrappers/profile-api";
import { get } from "sites-common/utils/lodash";
import { selectorJson } from "../selectors";
import { INVALIDATE_URL, RECEIVE_URL, REMOVE_URL } from "../constants";
import requestJson from "./common-utils";

export function receiveJson(key, data) {
  return {
    type: RECEIVE_URL,
    key,
    data,
    receivedAt: Date.now(),
  };
}

export function removeJsonField(key, data) {
  return {
    type: REMOVE_URL,
    key,
    data,
    status: "ITEM_CANCELLED",
  };
}

function shouldFetchJson(state, key) {
  const blob = selectorJson(state, key);

  if (blob.isFetching || blob.isLoaded) {
    return false;
  }
  if (blob.errorMessage) {
    return false;
  }
  return true;
}

export function invalidateJson(key, error = "Error Fetching URL") {
  return {
    type: INVALIDATE_URL,
    key,
    error,
  };
}

function invokeSrcmApi(key, apiParams = {}) {
  return (dispatch, getState) => {
    const {
      api,
      method = "GET",
      extraHdrs = {},
      extraData = {},
      forceAuth = false,
      methodParams = {},
      client = "baseClient",
    } = apiParams;
    const state = getState();

    const authToken = get(state, ["persist", "srcmtoken", "token"], "");
    if (!authToken) {
      dispatch(invalidateJson(key, "AuthToken Not Found"));
      return;
    }

    dispatch(requestJson(key));

    fetchSrcmAPI(
      api,
      authToken,
      method,
      extraHdrs,
      extraData,
      forceAuth,
      methodParams,
      client
    )
      .then((blob) => {
        if (blob) {
          dispatch(receiveJson(key, blob));
        } else {
          dispatch(invalidateJson(key, "Url Not Found"));
        }
      })
      .catch((e) => {
        // console.error(e);
        dispatch(invalidateJson(key, e.toString()));
      });
  };
}

export default function fetchSrcmApiIfNeeded(
  key,
  apiParams = {},
  force = false
) {
  return (dispatch, getState) => {
    if (force || shouldFetchJson(getState(), key)) {
      return dispatch(invokeSrcmApi(key, apiParams));
    }
    return null;
  };
}
