import PropTypes from "prop-types";

const applyProdQaConfig = ({ prod, qa }) =>
  process.env.GATSBY_BANNER === "PROD" ? prod : qa;

applyProdQaConfig.propTypes = {
  prod: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.shape([]),
  ]).isRequired,
  qa: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.shape([]),
  ]).isRequired,
};

export default applyProdQaConfig;
