import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "theme-ui";
import "../../fontawesome";

const Icon = ({ variant, sx, icon, ...restProps }) =>
  icon ? (
    <Box variant={variant} sx={sx}>
      <FontAwesomeIcon icon={icon} {...restProps} />
    </Box>
  ) : null;

Icon.propTypes = {
  icon: PropTypes.string,
};

export default React.memo(Icon);
