import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { profileApiCachedUrl } from "../../../service-wrappers/profile-api";
import ServerAutoSuggest from "../ServerAutoSuggest";

export const fetchState = (stateId) => {
  return fetch(profileApiCachedUrl("states-id", stateId)).then((R) => R.json());
};

const dflt = [
  {
    id: 4315,
    name: "Andhra Pradesh",
    country: "India",
    country_id: 358,
    code: "AP",
    active: true,
  },
  {
    id: 5187,
    name: "Telangana",
    country: "India",
    country_id: 358,
    code: "Telangana",
    active: true,
  },
  {
    id: 3734,
    name: "Maharashtra",
    country: "India",
    country_id: 358,
    code: "MH",
    active: true,
  },
  {
    id: 2173,
    name: "Kerala",
    country: "India",
    country_id: 358,
    code: "KL",
    active: true,
  },
  {
    id: 4312,
    name: "Karnataka",
    country: "India",
    country_id: 358,
    code: "KA",
    active: true,
  },
];

function getConfigs({ country, includeOnly }) {
  const config = {
    dflt: (includeOnly ? dflt.filter(includeOnly) : dflt).filter(
      (r) => !country || country === r.country
    ),

    fetch: fetchState,

    // Trigger suggestions
    getSuggestionValue: (suggestion) =>
      `${suggestion.name}, ${suggestion.country}`,
    // Render Each Option
    renderSuggestion: (suggestion) =>
      `${suggestion.name}, ${suggestion.country}`,
    api: (itrimValue) =>
      country
        ? profileApiCachedUrl(
            "states-country-prefix",
            country,
            itrimValue.substring(0, 1)
          )
        : profileApiCachedUrl("states-prefix", itrimValue.substring(0, 1)),
    data2results: (data, itrimValue, getSuggestionValue, dflt1) => {
      const dfltIds = dflt1.map((r) => r.id);
      const filterFn = (f, itrimValue1) =>
        getSuggestionValue(f).toLowerCase().startsWith(itrimValue1);
      return [
        ...dflt1.filter((f) => filterFn(f, itrimValue)),
        ...data.results
          .filter(
            (f) =>
              (!includeOnly || includeOnly(f)) &&
              f.active &&
              filterFn(f, itrimValue) &&
              !dfltIds.includes(f.id)
          )
          .filter((i, idx) => idx < 20),
      ];
    },
  };

  return config;
}

const State = ({ country, includeOnly, ...restprops }) => {
  return (
    <ServerAutoSuggest
      {...restprops}
      config={useMemo(
        () => getConfigs({ country, includeOnly }),
        [country, includeOnly]
      )}
    />
  );
};

State.propTypes = {
  country: PropTypes.string,
  includeOnly: PropTypes.func,
};

State.defaultProps = {
  country: "",
  includeOnly: null,
};

export default State;
