const { initializeIcons } = require("office-ui-fabric-react/lib/Icons");

initializeIcons(
  "https://static2.sharepointonline.com/files/fabric/assets/icons/",
  { disableWarnings: true }
);

module.exports = () => {
  // noop
};
