import { get } from "sites-common/utils/lodash";
import { initUrlBlob } from "./reducers";

const GLOBAL = "global";
const PERSIST = "persist";

const emptyToken = { token: "", user: "", ts: "" };

export const selectorSrcmProfile = (state) => state[PERSIST].user;
export const selectorSrcmToken = (state) =>
  get(state[PERSIST], "srcmtoken", emptyToken);
export const selectorGlobalConfig = (state) =>
  get(state[GLOBAL], "globalConfig", {});
export const selectorFirebaseAuthProfile = (state) => state.firebase.auth;
export const selectorAlert = (state) => state[GLOBAL].alert;

export const selectorJson = (state, key) =>
  get(state, [GLOBAL, "json", key], initUrlBlob);

export const selectorGlobals = (state, key, initialValue = undefined) =>
  get(state[GLOBAL].globals, key, initialValue);

export const selectorPersist = (state, key, initialValue = undefined) =>
  get(
    state,
    [PERSIST, "persist", ...(Array.isArray(key) ? key : [key])],
    initialValue
  );

const selectorSrcmApiStore = (state, collection) =>
  get(state.srcmstore.collections, collection, undefined);
export const selectorSrcmApiStoreCollectionParams = (state, collection) =>
  get(selectorSrcmApiStore(state, collection), ["apiParams"], undefined);
export const selectorSrcmApiStoreCollectionCount = (state, collection) =>
  get(selectorSrcmApiStore(state, collection), ["count"], 0);
export const selectorSrcmApiStoreDoc = (state, collection, docId) =>
  get(selectorSrcmApiStore(state, collection), ["data", `${docId}`], undefined);
export const selectorSrcmApiStoreData = (state, collection) =>
  get(selectorSrcmApiStore(state, collection), ["data"], {});
export const selectorSrcmApiStoreOrdered = (state, collection) =>
  get(selectorSrcmApiStore(state, collection), ["ordered"], []);

export const selectorParticipantsPanel = (state) => state.panels;
