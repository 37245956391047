import fetchSrcmAPIDecompose from "./fetchSrcmAPIDecompose";

export default function fetchSrcmAPI(
  api,
  authToken = "",
  method = "GET",
  extraHdrs = {},
  extraData = {},
  forceAuth = false,
  methodParams = {},
  client = "baseClient"
) {
  const [url, data, retHandler] = fetchSrcmAPIDecompose(
    api,
    authToken,
    method,
    extraHdrs,
    extraData,
    forceAuth,
    methodParams,
    client
  );

  return fetch(url, data).then((res) => {
    if (!res.ok) {
      return res.text().then((text) => {
        throw new Error(text);
      });
    }
    if (retHandler === "json") {
      return res.json();
    }
    if (retHandler === "text") {
      return res.text();
    }
    return res.json();
  });
}
