/** @jsx jsx */
import React from "react";
import PropTypes from "prop-types";
import { Flex, jsx } from "theme-ui";
import RadioItem from "./RadioItem";

const RadioOptions = ({ options, value, name, onChange, onClick, disabled }) =>
  options.map((option) => {
    const theOption = typeof option === "string" ? option : option.name;
    const checked = theOption === value;
    return (
      <RadioItem
        disabled={disabled}
        key={`radio-option-${option.name}`}
        option={option}
        checked={checked}
        name={name}
        onChange={onChange}
        onClick={onClick}
      />
    );
  });

const RadioInline = ({
  options,
  value,
  renderPrint,
  name,
  onChange,
  onClick,
  disabled,
}) => {
  if (renderPrint) {
    const option = options.find((o) => o.name === value);
    return renderPrint(option ? option.label : value);
  }
  return (
    <Flex
      sx={{
        justifyContent: "center",
        width: "100%",
        py: "10px",
        "> *:not(:first-of-type)": {
          ml: 10,
        },
      }}
    >
      <RadioOptions
        disabled={disabled}
        options={options}
        value={value}
        name={name}
        onChange={onChange}
        onClick={onClick}
      />
    </Flex>
  );
};

RadioInline.propTypes = {
  options: PropTypes.shape([]).isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  renderPrint: PropTypes.func,
};
RadioInline.defaultProps = {
  value: "",
  onChange: null,
  onClick: null,
  disabled: false,
  renderPrint: null,
};

export default React.memo(RadioInline);
