const themeUi = {
  breakpoints: ["576px", "768px", "992px", "1200px", "1400px"],
  layout: {
    dot: {
      borderRadius: "50%",
      display: "inline-block",
      backgroundColor: "#FFD600",
      marginRight: "3px",
    },
    wrapper: {
      maxWidth: ["100%", "540px", "720px", "960px", "1140px"],
      paddingLeft: "15px",
      paddingRight: "15px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    stickyMenu: {
      position: "sticky",
      top: 0,
      left: 0,
      width: "100%",
      background: "#fff",
      zIndex: 1000,
    },
    sectionPadding: {
      padding: "40px 0px",
    },
    home: {
      a: {
        textDecoration: "none",
        color: "#288bd0",
      },
    },
  },
  signUpButton: {
    border: "1px solid #76AA3A",
    color: "#76AA3A",
    outline: "none",
    cursor: "pointer",
    bg: "transparent",
    p: "4px",
    fontSize: "16px",
    ":hover": {
      border: "1px solid #fff",
      bg: "#76AA3A",
      color: "#fff",
    },
  },
  bannerButton: {
    display: ["none", "block"],
    fontSize: "20px",
    cursor: "pointer",
    backgroundColor: "#76AA3A",
    p: "3",
    pl: "5",
    pr: "5",
  },
  EBooksBannerCarosel: {
    ".slick-arrow": {
      borderRadius: "50%",
      background: "rgb(151, 148, 148)",
      opacity: 1,
      zIndex: 3,
    },
    ".slick-next": {
      right: "30px",
    },
    ".slick-prev": {
      left: ["30px", null, "-30px"],
    },
  },
  plainButton: {
    background: "none",
    borderRadius: "0px",
    border: "2px solid #7b7b7b",
    color: "#000",
  },
  agendaShadow: {
    content: "",
    position: "absolute",
    width: "100%",
    height: "1px",
    top: 0,
    right: 0,
    zIndex: -1,
    boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.75)",
  },
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#444",
    secondary: "#43679C",
    accent: "#609",
    muted: "#f6f6f6",
    sectionBgOne: "#fff",
    sectionBgTwo: "#f4f4f4",
    buttonBg: "#2075bf",
  },
  fonts: {
    body: "gotham-book",
    heading: "gotham-book",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  a: {
    textDecoration: "none !important",
    color: "#288bd0",
  },
  signUpInput: {
    background: "transparent",
    outline: "none",
    border: "1px solid #4A4A49",
    p: "6px 8px",
    width: "100%",
  },
  styles: {
    backgroundColor: "#fff",
    root: {
      backgroundColor: "#fff",
      fontFamily: "body",
      margin: "0px",
      fontSize: "18px",
    },
    a: {
      textDecoration: "none !important",
      color: "#288bd0",
    },
    p: {
      margin: "10px 0px",
      lineHeight: "33px",
      color: "primary",
    },
    h1: {
      fontSize: "80px",
      color: "secondary",
      fontWeight: 100,
      textAlign: "center",
    },
    h2: {
      fontSize: "34px",
      color: "secondary",
      fontWeight: 100,
    },
    h3: {
      fontSize: "32px",
      color: "secondary",
      fontWeight: 100,
      margin: 0,
    },
    h4: {
      fontSize: "20px",
      color: "#515151",
      margin: 0,
      marginBottom: "10px",
      fontWeight: 100,
    },

    input: {
      div: {
        border: "none",
        input: {
          border: "1px solid rgba(0, 0, 0, 0.1)",
        },
        textarea: {
          border: "1px solid rgba(0, 0, 0, 0.1)",
        },
      },
    },
    footer: {
      fontSize: "16px",
    },
  },
  KanhaBookingMainPage: {
    overflow: "auto",
    width: "70%",
    margin: "30px auto",
    ".kanha-header-wrapper": {
      paddingY: 2,
      ".kanha-header": {
        fontSize: "22px",
        py: 3,
        alignItems: "center",
        borderBottom: "1px solid #cccccc",
        justifyContent: "space-between",
        "@media (max-width: 480px)": {
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
        ".kanha-header-title": {
          paddingRight: 3,
          fontSize: "22px",
        },
      },
    },
    ".kanha-filter-wrapper": {
      p: 3,
      boxShadow: "1px 2px 4px #d2d2d261",
      backgroundColor: "#f6f6f6",
      ".kanha-filter": {
        mb: 3,
        alignItems: "center",
        justifyContent: "end",
        gap: 2,
        "@media (max-width: 480px)": {
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  },
  AddEditForm: {
    ".form-wrapper": {
      ".form-group": {
        marginBottom: 3,
        label: {
          marginBottom: 2,
        },
      },
      ".form-group-horizontal": {
        display: "flex",
        gap: 3,
        mb: 3,
        ".form-group": {
          marginBottom: 3,
          label: {
            marginBottom: 2,
          },
        },
      },
      ".form-btn-group": {
        gap: 3,
        float: "right",
        pb: 3,
      },
      ".error": { color: "red", fontSize: "14px" },
      ".success": { color: "#008000" },
      ".error-input": { border: "1px solid red" },
      ".required": { color: "red" },
    },
  },
};

export default themeUi;
