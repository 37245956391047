/** @jsx jsx */
import React from "react";
import PropTypes from "prop-types";
import { Avatar as ThemeUIAvatar, Flex, jsx /* useThemeUI */ } from "theme-ui";

const InitialsAvatar = ({ show, initials, ...restProps }) =>
  show ? (
    <Flex {...restProps}>
      <span>{initials}</span>
    </Flex>
  ) : null;

InitialsAvatar.propTypes = {
  show: PropTypes.bool.isRequired,
  initials: PropTypes.string.isRequired,
};

const TheAvatarLayout = ({ avatar, initialsAvatar }) => (
  <>
    <ThemeUIAvatar {...avatar} />
    <InitialsAvatar {...initialsAvatar} />
  </>
);

TheAvatarLayout.propTypes = {
  avatar: PropTypes.objectOf(PropTypes.any).isRequired,
  initialsAvatar: PropTypes.objectOf(PropTypes.any).isRequired,
};

const TheAvatar = ({ src, initials, size }) => {
  // const { theme } = useThemeUI();
  const [state, setState] = React.useState({
    isLoading: true,
    avatar: {
      sx: {
        // Getting error: Cannot read property 'avatar1' of undefined
        // ...theme.images.avatar1,
        height: size,
        width: size,
        border: "1px solid",
        borderColor: "muted",
        cursor: "pointer",
        display: "none",
      },
    },
    initialsAvatar: {
      sx: {
        // Getting error: Cannot read property 'avatarWrapper' of undefined
        // ...theme.flex.avatarWrapper,
        flexDirection: "column",
        justifyContent: "center",
        bg: "secondary",
        color: "background",
        alignItems: "center",
        borderRadius: "50%",
        borderColor: "muted",
        height: size,
        width: size,
        span: {
          fontSize: Math.round(size * 0.5),
        },
        cursor: "pointer",
      },
    },
  });

  const theAvatar = {
    avatar: {
      ...state.avatar,
      src,
      onLoad: React.useCallback(() => {
        setState((currentState) => ({
          ...currentState,
          isLoading: false,
          avatar: {
            ...currentState.avatar,
            sx: {
              ...currentState.avatar.sx,
              display: "unset",
            },
          },
        }));
      }, []),
    },
    initialsAvatar: {
      ...state.initialsAvatar,
      show: React.useMemo(() => state.isLoading, [state.isLoading]),
      initials,
    },
  };

  return <TheAvatarLayout {...theAvatar} />;
};

TheAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  size: PropTypes.number,
};

TheAvatar.defaultProps = {
  size: 36,
};

export default React.memo(TheAvatar);
