import { useCallback, useReducer } from "react";

function reducer(state, action) {
  let nKeys;
  switch (action.type) {
    case "filter":
      return state.filter(action.f);
    case "append":
      return [...state, ...action.n];
    case "merge":
      nKeys = action.n.map(action.f);
      return [
        ...state.filter((r) => !nKeys.includes(action.f(r))),
        ...action.n,
      ];
    case "push":
      return [...state, action.v];
    case "set":
      return [...action.n];
    default:
      throw new Error();
  }
}

function useMyArray(d = []) {
  const [s, dispatch] = useReducer(reducer, d);

  const set = useCallback((n = []) => dispatch({ type: "set", n }), []);
  const filter = useCallback((f) => dispatch({ type: "filter", f }), []);
  const append = useCallback((n = []) => dispatch({ type: "append", n }), []);
  const merge = useCallback(
    (n = [], f) => dispatch({ type: "merge", n, f }),
    []
  );
  const push = useCallback((v) => dispatch({ type: "push", v }), []);

  return [s, { append, push, set, filter, merge }];
}
export default useMyArray;
