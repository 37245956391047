import { get } from "sites-common/utils/lodash";
import { fetchSrcmAPI } from "sites-common/service-wrappers/profile-api";
import { RECEIVE_SRCM_API_STORE_DOC } from "../constants";

function receiveSrcmApiStoreDoc(collection, docId, data) {
  return {
    type: RECEIVE_SRCM_API_STORE_DOC,
    docId,
    data,
    collection,
    receivedAt: Date.now(),
  };
}

function fetchSrcmApiStoreDispatch(
  state,
  dispatch,
  collection,
  apiParams,
  docId,
  method,
  methodParams,
  metaParams
) {
  const {
    api,
    extraHdrs = {},
    extraData = {},
    forceAuth = false,
    primaryKey = "id",
    client = "baseClient",
  } = apiParams;

  const { api_workflow = "", use_method } = metaParams;

  const authToken = get(state, ["persist", "srcmtoken", "token"], "");
  if (!authToken && forceAuth) {
    throw Error("AuthToken Not Found");
  }

  const newapi = docId
    ? `${api}/${docId}/${api_workflow}/`
        .replaceAll("//", "/")
        .replaceAll("//", "/")
        .trim()
    : api;

  return fetchSrcmAPI(
    newapi,
    authToken,
    use_method || method,
    extraHdrs,
    extraData,
    forceAuth,
    methodParams,
    client
  ).then((blob) => {
    if (
      blob &&
      primaryKey in blob &&
      blob[primaryKey] !== 0 &&
      blob[primaryKey] !== "" &&
      blob[primaryKey] !== null &&
      blob[primaryKey] !== undefined
    ) {
      dispatch(receiveSrcmApiStoreDoc(collection, blob[primaryKey], blob));
      return blob;
    }
    throw Error(`Api Error: ${JSON.stringify(blob)}`);
  });
}

export default function fetchSrcmApiStorePromise(
  state,
  dispatch,
  collection,
  docId,
  method,
  methodParams = {},
  metaParams = {},
  force = false
) {
  const collectionBlob = state.srcmstore.collections[collection];
  const { apiParams, data } = collectionBlob;

  if (method === "GET" && `${docId}` in data) {
    return Promise.resolve(data[`${docId}`]);
  }

  if (["POST", "PUT", "GET", "PATCH"].includes(method)) {
    if (
      force ||
      method === "POST" ||
      method === "PATCH" ||
      method === "PUT" ||
      (method === "GET" && `${docId}` in data)
    ) {
      return fetchSrcmApiStoreDispatch(
        state,
        dispatch,
        collection,
        apiParams,
        docId,
        method,
        methodParams,
        metaParams
      );
    }
  }
  return Promise.reject(Error("Invalid Function Call"));
}
