import { set, omit } from "sites-common/utils/lodash";

import { SET_USER, UNSET_USER, SET_PERSIST, SET_USER_TOKEN } from "./constants";

import { persistIt } from "./localStorageFn";

const emptyToken = { token: "", user: "", ts: "" };

const persistReducers = (state = {}, action) => {
  let s1;
  let s2;
  let s6;
  let s3;
  let s3d;
  let s4;

  switch (action.type) {
    case SET_USER:
      s1 = {
        ...state,
        user: action.user,
      };
      persistIt(s1);
      return s1;
    case UNSET_USER:
      s2 = {
        // ...state,
        user: null,
        srcmtoken: emptyToken,
      };
      persistIt(s2);
      return s2;
    case SET_USER_TOKEN:
      s6 = {
        ...state,
        srcmtoken: action.srcmtoken,
      };
      persistIt(s6);
      return s6;

    case SET_PERSIST:
      if (action.subtype === "deleteKey") {
        s3d = {
          ...state,
          persist: {
            ...omit(state.persist, [action.key]),
          },
        };
        persistIt(s3d);
        return s3d;
      }
      if (typeof action.key === "string" && !action.key.includes(".")) {
        s3 = {
          ...state,
          persist: {
            ...state.persist,
            [action.key]: action.value,
          },
        };
        persistIt(s3);
        return s3;
      }
      s4 = {
        ...state,
        persist: {
          ...state.persist,
        },
      };
      set(s4.persist, action.key, action.value);
      persistIt(s4);
      return s4;

    default:
      return state;
  }
};

export default persistReducers;
