/** @jsx jsx */

const Header = () => null;

const accessControl = {
  msgUnAuthorized: (r) => `Hello, ${r.name}`,
  showSignInButton: "Sign In to access this page",
  conditions: (r) => !!r,
};

const AppConfig = {
  Header,
  accessControl,
};
export default AppConfig;
