import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { globalReducers, srcmReducers } from "./reducers";
import persistReducers from "./persistReducers";
import panelsReducer from "./panelsReducer";

// import { persistReducer } from 'redux-persist'
// import localStorage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

const reducersAll = {
  firebase:
    // persistReducer(
    // { key: 'firebaseState', storage: localStorage, stateReconciler: hardSet },
    firebaseReducer,
  // )
  firestore: firestoreReducer,
  srcmstore: srcmReducers,
  global: globalReducers,
  persist:
    // persistReducer({key: "persistState", storage: localStorage, stateReconciler: hardSet},
    persistReducers,
  panels: panelsReducer,
  // )
};

export default reducersAll;
