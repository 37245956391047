/** @jsx jsx */
import { jsx, ThemeProvider } from "theme-ui";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import adaptTheme from "sites-common/gatsby-utils/adaptTheme";
import { DefaultButton } from "office-ui-fabric-react";
import { Modal } from "../components/Modal";
import TheAvatar from "../components/ui/TheAvatar";
import { PresetThemes } from "../shared";
import ensureSignedInSilent from "./ensureSignedInSilent";
import SignOutButton from "./SignOutButton";
import useAuth from "./useAuth";

const Avatar = ensureSignedInSilent(
  ({ theme = "bootstrap", avatarSize = 36 }) => {
    const { srcmProfile } = useAuth();
    const { photoUrl, name, isPrefect, email } = srcmProfile;
    const initials = name?.substr(0, 1).replace(" ", "@");

    const [show, { setTrue, setFalse }] = useMyBoolean(false);

    const a = adaptTheme(PresetThemes[theme]);

    return (
      <ThemeProvider theme={a}>
        <div>
          {/* <SafeImage onClick={() => setShow(true)} src={photoUrl} alt={initials}
          style={{
            borderRadius: "50%",
            height: `${size}px`, width: `${size}px`, fontSize: `${size}px`,
            color: "white", backgroundColor, cursor: "pointer"
          }}
        /> */}
          <div onKeyDown={setTrue} onClick={setTrue} role="button" tabIndex="0">
            <TheAvatar src={photoUrl} initials={initials} size={avatarSize} />
          </div>
          {show && (
            <Modal
              handleClose={setFalse}
              showCloseBtn={false}
              position="top-right"
            >
              <table>
                <tbody>
                  <tr>
                    <td>
                      <TheAvatar src={photoUrl} initials={initials} size={54} />
                      {/* <SafeImage src={photoUrl} alt={initials}
                    style={{
                      borderRadius: "50%",
                      height: `${60}px`, width: `${60}px`, fontSize: `${60}px`,
                      color: "white", backgroundColor: "grey"
                    }
                    }
                  /> */}
                    </td>
                    <td style={{ paddingLeft: "10px" }}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "black",
                        }}
                      >
                        {name}
                      </div>
                      <div style={{ fontSize: "14px", color: "black" }}>
                        {email}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td style={{ paddingLeft: "10px" }}>
                      <div style={{ fontSize: "12px", color: "black" }}>
                        {isPrefect ? "Preceptor" : ""}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ paddingTop: "10px" }}>
                      <div sx={{ display: "flex" }}>
                        <DefaultButton
                          target="_blank"
                          href="https://my.heartfulness.org/profile"
                          text="My Account "
                        />
                        <SignOutButton />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Modal>
          )}
        </div>
      </ThemeProvider>
    );
  }
);

export default Avatar;
