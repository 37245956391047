/** @jsx jsx */
import { get } from "sites-common/utils/lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { jsx, Box } from "theme-ui";

import { selectorGlobalConfig } from "../state/selectors";

const Tos = (props) => {
  const { country = "in" } = props;
  const { tosUrl, privacyPolicyUrl } = useSelector(selectorGlobalConfig);

  return (
    <Box {...props}>
      By continuing you agree to our&nbsp;
      <a
        rel="noreferrer"
        target="_blank"
        href={get(tosUrl, country, get(tosUrl, ["in"], "#"))}
      >
        terms of service
      </a>
      &nbsp;and &nbsp;
      <a
        rel="noreferrer"
        target="_blank"
        href={get(
          privacyPolicyUrl,
          country,
          get(privacyPolicyUrl, ["in"], "#")
        )}
      >
        privacy policy
      </a>
    </Box>
  );
};

Tos.propTypes = {
  sx: PropTypes.shape({}),
  country: PropTypes.string,
};

Tos.defaultProps = {
  sx: { textAlign: "center", my: 1, fontWeight: "300", fontSize: "0.65rem" },
  country: "in",
};

export default Tos;
