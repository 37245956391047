/** @jsx jsx */
import React from "react";
import PropTypes from "prop-types";
import { Label, Radio, Text, Flex, jsx } from "theme-ui";

const RadioItemLayout = ({
  label,
  controlWrapper,
  radio,
  textWrapper,
  text,
}) => (
  <Label {...label}>
    <Flex {...controlWrapper}>
      <Radio {...radio} />
      <Text {...textWrapper}>{text}</Text>
    </Flex>
  </Label>
);

RadioItemLayout.propTypes = {
  label: PropTypes.object,
  controlWrapper: PropTypes.object,
  radio: PropTypes.object,
  text: PropTypes.string,
  textWrapper: PropTypes.object,
};

const RadioItem = ({ name, option, checked, onClick, onChange, disabled }) => {
  const memos = React.useMemo(
    () => ({
      text: typeof option === "string" ? option : option.label,
      label: {
        sx: {
          p: 2,
          cursor: "pointer",
          fontSize: "0.8em",
          border: `1px solid transparent`,
          borderColor: "secondary",
          borderRadius: "30px",
          bg: checked ? "secondary" : "background",
          color: checked ? "background" : "secondary",
          fontFamily: "body",
          pointerEvents: disabled && "none",
        },
      },
      controlWrapper: {
        sx: {
          alignItems: "center",
        },
      },
      radio: {
        sx: {
          bg: checked && "background",
          color: "secondary",
        },
      },
      textWrapper: {
        sx: {
          marginLeft: "5px",
          marginRight: "5px",
        },
      },
    }),
    [option]
  );

  const radioItem = {
    label: {
      ...memos.label,
      onClick: React.useCallback(() => {
        onClick(option, name);
      }, [option, name, onClick]),
    },
    controlWrapper: memos.controlWrapper,
    radio: {
      ...memos.radio,
      name,
      checked,
      onChange: React.useCallback(() => {
        onChange(option, name);
      }, [option, name, onClick]),
    },
    text: memos.text,
    textWrapper: memos.textWrapper,
  };

  return <RadioItemLayout {...radioItem} />;
};

RadioItem.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  option: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default React.memo(RadioItem);
