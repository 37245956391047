import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { profileApiCachedUrl } from "../../../service-wrappers/profile-api";
import ServerAutoSuggest from "../ServerAutoSuggest";

export const fetchCountry = (countryid) => {
  return fetch(profileApiCachedUrl("countries-id", countryid)).then((R) =>
    R.json()
  );
};

const dflt = [
  { id: 358, name: "India", code: "IN", active: true },
  { id: 488, name: "United States", code: "US", active: true },
  { id: 292, name: "Canada", code: "CA", active: true },
  { id: 255, name: "United Arab Emirates", code: "AE", active: true },
  { id: 486, name: "United Kingdom", code: "GB", active: true },
  { id: 411, name: "Malaysia", code: "MY", active: true },
];

function getConfigs({ includeOnly }) {
  const config = {
    dflt: includeOnly ? dflt.filter(includeOnly) : dflt,
    fetch: fetchCountry,
    // Trigger suggestions
    getSuggestionValue: (suggestion) => suggestion.name,
    // Render Each Option
    renderSuggestion: (suggestion) => suggestion.name,
    api: (/* itrimValue */) => profileApiCachedUrl("countries-all"),
    data2results: (data, itrimValue, getSuggestionValue, dflt1) => {
      const dfltIds = dflt.map((r) => r.id);
      const filterFn = (f, itrimValue1) =>
        getSuggestionValue(f).toLowerCase().startsWith(itrimValue1);
      const results = [
        ...dflt1.filter((f) => filterFn(f, itrimValue)),
        ...data.results.filter(
          (f) =>
            (!includeOnly || includeOnly(f)) &&
            f.active &&
            filterFn(f, itrimValue) &&
            !dfltIds.includes(f.id)
        ),
      ];
      return results;
    },
  };

  return config;
}

const Country = ({ includeOnly, ...restprops }) => {
  return (
    <ServerAutoSuggest
      {...restprops}
      config={useMemo(() => getConfigs({ includeOnly }), [includeOnly])}
    />
  );
};

Country.propTypes = {
  includeOnly: PropTypes.func,
};
Country.defaultProps = {
  includeOnly: null,
};

export default Country;
