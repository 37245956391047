export function initialPersistStateFn() {
  const pKey = "pstate";
  const emptyToken = { token: "", user: "", ts: "" };
  const d = {
    user: null,
    srcmtoken: emptyToken,
    persist: {},
  };

  try {
    if (typeof window !== "undefined") {
      const ret = JSON.parse(localStorage.getItem(pKey));
      if (ret) {
        return ret;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return d;
}

export const persistIt = (json) => {
  const pKey = "pstate";
  try {
    if (typeof window !== "undefined") {
      localStorage.setItem(pKey, JSON.stringify(json));
    }
  } catch (e) {
    console.error(e);
  }
};
