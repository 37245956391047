import PropTypes from "prop-types";
import { mySrcmConfig, l } from "./common-utils";

const profileApiCachedUrl = (api, param1 = "", param2 = "") => {
  let path = "";
  switch (api) {
    case "cities-id":
      path = `/srcmapi/cities/id/${param1}.json`;
      break;
    case "cities-country-prefix":
      path = `/srcmapi/cities/${l(param1)}/${l(param2)}.json`;
      break;
    case "cities-prefix":
      path = `/srcmapi/cities/${l(param1)}.json`;
      break;
    case "states-id":
      path = `/srcmapi/states/id/${param1}.json`;
      break;
    case "states-country-prefix":
      path = `/srcmapi/states/${l(param1)}/${l(param2)}.json`;
      break;
    case "states-prefix":
      path = `/srcmapi/states/${l(param1)}.json`;
      break;
    case "substates-id":
      path = `/srcmapi/substates/id/${param1}.json`;
      break;
    case "substates-country-prefix":
      path = `/srcmapi/substates/${l(param1)}/${l(param2)}.json`;
      break;
    case "substates-prefix":
      path = `/srcmapi/substates/${l(param1)}.json`;
      break;
    case "countries-id":
      path = `/srcmapi/countries/id/${param1}.json`;
      break;
    case "countries-all":
      path = `/srcmapi/countries/all.json`;
      break;
    case "groups-id":
      path = `/srcmapi/groups/id/${param1}.json`;
      break;
    case "groups-parent-id":
      path = `/srcmapi/groups/parent/${param1}.json`;
      break;
    case "groups-center-prefix":
      path = `/srcmapi/groups/center/${l(param1)}.json`;
      break;
    default:
      break;
  }

  return `${mySrcmConfig.profileCacheServer}${path}`;
};

profileApiCachedUrl.propTypes = {
  api: PropTypes.oneOf([
    "states-id",
    "states-country-prefix",
    "states-prefix",
    "substates-id",
    "substates-country-prefix",
    "substates-prefix",
    "cities-id",
    "cities-country-prefix",
    "cities-prefix",
    "countries-all",
    "countries-id",
    "groups-id",
    "groups-parent-id",
    "groups-center-prefix",
  ]).isRequired,
  param1: PropTypes.string,
  param2: PropTypes.string,
};

export default profileApiCachedUrl;
