module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":690,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#013B28"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Events Management - Heartfulness","short_name":"Events Management - Heartfulness","description":"Events Management is a meditation app that helps with relaxation, meditation, rejuvenation, introspection and deep reflection.","start_url":"/","background_color":"#9B7E29","theme_color":"#013B28","display":"standalone","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"22d253bbf3905840fe2ee791fd2e6ee1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-plugin-hfn-profile/gatsby-browser.js'),
      options: {"plugins":[],"pathToRootReducerModule":"./src/state/appReducer","firebaseAuthConfig":{"apiKey":"AIzaSyDvmxKH7738HE1T3fpJYUvi4BcEAakqQbQ","authDomain":"auth-qa.heartfulness.org","projectId":"unifiedplatform-qa"},"firebaseStoreConfig":{"apiKey":"AIzaSyCbz8YnmJaQnVTQy-EmftHux3SmLqi7134","authDomain":"hfn-registration-qa.firebaseapp.com","databaseURL":"https://hfn-registration-qa.firebaseio.com","projectId":"hfn-registration-qa","storageBucket":"hfn-registration-qa.appspot.com","messagingSenderId":"117921689659","appId":"1:117921689659:web:6791beccca0944602cec1e","measurementId":"G-F5P3LCLZGW"},"splashScreen":"loading..."},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["gotham"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-61002286-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
