const RootWrapper = require("sites-common/gatsby-utils/WrapRoot").default;
const WrapPageAccessControl = require("gatsby-plugin-hfn-profile/auth/WrapPageAccessControl")
  .default;
require("sites-common/gatsby-utils/setupOfficeFabric");
const React = require("react");
const { navigate } = require("@reach/router");
const PropTypes = require("prop-types");

const appConfigRoutes = require("./gatsby-apps-config");

const defaultConfig = require("./src/components/BaseComponents/AppConfig")
  .default;

const apps = Object.keys(appConfigRoutes);

function RenderHeader({ Header, isHome, appHome }) {
  const goHome = React.useCallback(() => navigate(appHome), [appHome]);

  return React.createElement(Header, { isHome, goHome });
}

RenderHeader.propTypes = {
  Header: PropTypes.element.isRequired,
  isHome: PropTypes.bool.isRequired,
  appHome: PropTypes.func.isRequired,
};

function WrapLayout({ element, props }) {
  const { path } = props;

  let Config = defaultConfig;
  let isHome = false;
  let appHome = path;

  if (path === "/") {
    isHome = true;
    Config = require("./src/components/HomeComponents/AppConfig").default;
  } else {
    apps.forEach((p) => {
      if (path.startsWith(p)) {
        if (path === p) {
          isHome = true;
        }
        appHome = p;
        Config =  appConfigRoutes[p].config;
      }
    });
  }

  const accessControl =
    "accessControl" in Config
      ? Config.accessControl
      : defaultConfig.accessControl;

  return (
    <>
      <RenderHeader Header={Config.Header} isHome={isHome} appHome={appHome} />
      <WrapPageAccessControl element={element} accessControl={accessControl} />
    </>
  );
}

WrapLayout.propTypes = {
  element: PropTypes.element.isRequired,
  props: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

exports.wrapPageElement = WrapLayout;
exports.wrapRootElement = RootWrapper;
