import React from "react";
import { ThemeProvider } from "theme-ui";
import PropTypes from "prop-types";
import adaptTheme from "./adaptTheme";

const theme = {
  colors: {
    secondary: "#6b7b8c",
    highlight: "hsl(10, 40%, 90%)",
    purple: "hsl(250, 60%, 30%)",
    gray: "hsl(10, 20%, 50%)",
    text: "#000200",
    background: "#FFFFFF",
    muted: "rgba(0,0,0,.1)",
    primary: "#F25F5C",
    primaryDark: "#B51916",
    primaryLight: "#FCBAB1",
    blue: "#B6DEE2",
    blueDark: "#247BA0",
    greenDark: "#2D5948",
    green: "#B2E4DC",
    yellowDark: "#FFDA3A",
    yellow: "#FCF5C7",
    almond: "#f5f0e1",
  },
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  fontWeights: {
    body: 400,
    heading: 700,
    display: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: "4px",
    },
    display: {
      variant: "textStyles.heading",
      fontSize: [5, 6],
      fontWeight: "display",
      letterSpacing: "-0.03em",
      mt: 3,
    },
  },
  styles: {
    Container: {
      p: 3,
      maxWidth: 1024,
    },
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      variant: "textStyles.display",
    },
    h2: {
      variant: "textStyles.heading",
      fontSize: 5,
    },
    h3: {
      variant: "textStyles.heading",
      fontSize: 4,
    },
    h4: {
      variant: "textStyles.heading",
      fontSize: 3,
    },
    h5: {
      variant: "textStyles.heading",
      fontSize: 2,
    },
    h6: {
      variant: "textStyles.heading",
      fontSize: 1,
    },
    a: {
      color: "primary",
      "&:hover": {
        color: "secondary",
      },
    },
    pre: {
      variant: "prism",
      fontFamily: "monospace",
      fontSize: 1,
      p: 3,
      color: "text",
      bg: "muted",
      overflow: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      color: "secondary",
      fontSize: 1,
    },
    inlineCode: {
      fontFamily: "monospace",
      color: "secondary",
      bg: "muted",
    },
    table: {
      width: "100%",
      my: 4,
      borderCollapse: "separate",
      borderSpacing: 0,
      "th,td": {
        textAlign: "left",
        py: "4px",
        pr: "4px",
        pl: 0,
        borderColor: "muted",
        borderBottomStyle: "solid",
      },
    },
    th: {
      verticalAlign: "bottom",
      borderBottomWidth: "2px",
    },
    td: {
      verticalAlign: "top",
      borderBottomWidth: "1px",
    },
    hr: {
      border: 0,
      borderBottom: "1px solid",
      borderColor: "muted",
    },
    img: {
      maxWidth: "100%",
    },
  },
  cards: {
    "event-header": {
      background: "#DED",
      color: "primary",
      p: 3,
    },
    "event-info-card": {
      border: "1px solid #DED",
      color: "text",
      p: 3,
      minHeight: "300px",
    },
  },
};

const a = adaptTheme(theme);

const WrapRoot = ({ element }) => (
  <ThemeProvider theme={a}>{element}</ThemeProvider>
);

WrapRoot.propTypes = {
  element: PropTypes.element.isRequired,
};

export default WrapRoot;
