/** @jsx jsx */

import { jsx } from "theme-ui";
import { useState, useCallback } from "react";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { uniq } from "sites-common/utils/lodash";
import { validateRE } from "sites-common/utils/validations";
import { selectorParticipantsPanel } from "../../state/selectors";
import {
  addParticipant,
  discardParticipantPage,
} from "../../state/actions/participantsPanel";
import personFormFields, {
  alternateNumber,
  govFields,
} from "./personFormFields";
import { PrimaryButton, TransparentButton } from "./StyledButtons";

let fields = [...personFormFields];

const validateForm = (r, missingFieldsKey, abDetails, conditionProp) => {
  const phonePattern = RegExp("^[+][0-9]*$");
  if (!!r && !!r.mobile) {
    if (!r.mobile.startsWith("+")) {
      return "Mobile number needs to start with +(country code)";
    }
    if (!phonePattern.test(r.mobile)) {
      return "Enter a valid mobile number.";
    }
    if (r.mobile.length > 17) {
      return "Enter a valid mobile number.";
    }
  }

  if (!!r && !!r?.alternate_phone) {
    if (!r.alternate_phone.startsWith("+")) {
      return "Alternate Mobile No needs to start with +(country code)";
    }
    if (!phonePattern.test(r.alternate_phone)) {
      return "Enter a valid alternate mobile number.";
    }
    if (r.alternate_phone.length > 17) {
      return "Enter a valid alternate mobile number.";
    }
  }

  if (!!r && !!r?.alternate_phone && !!r.mobile) {
    if (r.alternate_phone === r.mobile) {
      return "Mobile number and alternate mobile number cannot be same.";
    }
  }
  if (abDetails?.age_group && conditionProp?.ageLimitation) {
    const [, lim2] = abDetails.age_group.split("-");
    if (conditionProp?.ageLimitation.min > Number(lim2)) {
      return `Age lesser than ${conditionProp?.ageLimitation.min} not allowed.`;
    }
    if (conditionProp?.ageLimitation.max < Number(lim2)) {
      return `Age greater than ${conditionProp?.ageLimitation.max} not allowed.`;
    }
  }
  if (conditionProp?.ageLimitation?.max && !!r?.age_group) {
    if (r?.age_group > conditionProp?.ageLimitation.max) {
      return `Age greater than ${conditionProp?.ageLimitation.max} not allowed.`;
    }
  }

  if (conditionProp?.ageLimitation?.min && !!r?.age_group) {
    if (r?.age_group < conditionProp?.ageLimitation.min) {
      return `Age lesser than ${conditionProp?.ageLimitation.min} not allowed.`;
    }
  }

  if (
    conditionProp?.maxMaleParticipant &&
    !!r?.gender &&
    r.gender === "M" &&
    ((conditionProp?.persons && conditionProp.persons.length > 0) ||
      (conditionProp?.existing && conditionProp?.existing?.length))
  ) {
    let existingMales = 0;
    if (conditionProp.persons && conditionProp.persons.length > 0) {
      existingMales += conditionProp.persons.filter(
        (a) => a.gender === "M"
      )?.length;
    }

    if (conditionProp.existing && conditionProp.existing.length > 0) {
      existingMales += conditionProp.existing.filter(
        (a) => a.gender === "M"
      )?.length;
    }
    if (existingMales >= conditionProp.maxMaleParticipant) {
      return `Only ${conditionProp?.maxMaleParticipant} male participant${
        conditionProp?.maxMaleParticipant > 1 ? "s" : ""
      } allowed per PNR`;
    }
  }

  if (
    conditionProp?.maxFemaleParticipant &&
    !!r?.gender &&
    r.gender === "F" &&
    ((conditionProp?.persons && conditionProp.persons.length > 0) ||
      (conditionProp?.existing && conditionProp?.existing?.length))
  ) {
    let existingFemales = 0;
    if (conditionProp.persons && conditionProp.persons.length > 0) {
      existingFemales += conditionProp.persons.filter(
        (a) => a.gender === "F"
      )?.length;
    }

    if (conditionProp.existing && conditionProp.existing.length > 0) {
      existingFemales += conditionProp.existing.filter(
        (a) => a.gender === "F"
      )?.length;
    }
    if (existingFemales >= conditionProp.maxFemaleParticipant) {
      return `Only ${conditionProp?.maxFemaleParticipant} female participant${
        conditionProp?.maxFemaleParticipant > 1 ? "s" : ""
      } allowed per PNR`;
    }
  }

  if (!!r && !!r?.gov_id && !!r?.gov_id_type) {
    switch (r.gov_id_type) {
      case "pan_card":
        if (!validateRE("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$", r?.gov_id)) {
          return "Invalid Pan Card";
        }
        break;
      case "aadhar":
        if (!validateRE("^[0-9]{12}$", r?.gov_id)) {
          return "Invalid Aadhar";
        }
        break;
      case "driving_license":
        if (
          !validateRE(
            "^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$",
            r?.gov_id
          )
        ) {
          return "Invalid Driving License";
        }
        break;

      default:
        if (r?.gov_id.length < 6) {
          return "Id proof incorrect.";
        }
        break;
    }
  }

  if (!!r && !r?.acknowledgement) {
    return "Kindly provide the consent before proceeding.";
  }

  if (
    missingFieldsKey.length &&
    missingFieldsKey.includes("mobile") &&
    missingFieldsKey.includes("email")
  ) {
    if (!!r && !r.mobile && !r.email) {
      return "Enter either Mobile or Email";
    }
  }

  return false;
};
function PersonForm({
  onDiscard,
  fieldName,
  fieldValue,
  abhyasiDetails = null,
  PersonCard = null,
}) {
  const {
    checkIdProof,
    persons,
    ageLimitation,
    maxNumParticipants,
    mandatePanelFields,
    maxMaleParticipant,
    maxFemaleParticipant,
    existing,
  } = useSelector(selectorParticipantsPanel);
  const dispatch = useDispatch();
  const [data] = useState({ [fieldName]: fieldValue });
  const missingFields = ["has_oci", "citizen_of_country", "acknowledgement"];
  const conditionProp = {
    persons,
    ageLimitation,
    maxNumParticipants,
    maxMaleParticipant,
    maxFemaleParticipant,
    existing,
  };

  const onSubmit = useCallback(
    (vals, onS) => {
      let newrec = {};
      if (abhyasiDetails) {
        newrec = {
          ...abhyasiDetails,
          ...vals,
          extras: { ...vals },
        };
      } else {
        newrec = {
          ...vals,
          id: uuidv4(),
          ref: "B99999999",
          [fieldName]: fieldValue,
          q: fieldValue,
        };
      }

      Promise.resolve(newrec).then((v) => {
        dispatch(addParticipant(v));
        dispatch(discardParticipantPage());
        onS();
      });
    },
    [fieldName, fieldValue, dispatch, abhyasiDetails]
  );
  const rFields = [...personFormFields];
  if (checkIdProof) {
    const gField = rFields.find((item) => item.name === "gender");
    if (gField.options.length > 0) {
      const value = gField.options.find((item) => item.value === "U");
      if (value) {
        gField.options.pop();
      }
    }
  }

  if (abhyasiDetails) {
    Object.entries(abhyasiDetails).map(([key, val]) => {
      if (!val) {
        missingFields.push(key);
      }
      return null;
    });
    const filteredFields = personFormFields.filter((f) =>
      f.name === "city_id"
        ? missingFields.includes("city")
        : missingFields.includes(f.name)
    );
    const defaultFields = checkIdProof
      ? filteredFields.slice(0, filteredFields.length - 1)
      : filteredFields;

    if (
      mandatePanelFields &&
      Array.isArray(mandatePanelFields) &&
      mandatePanelFields.length > 0
    ) {
      mandatePanelFields.map((i) => {
        if (missingFields.includes(i)) {
          const index = defaultFields.findIndex((f) => f.name === i);
          if (index >= 0)
            defaultFields[index] = {
              ...defaultFields[index],
              props: { ...(defaultFields[index]?.props || {}), required: true },
            };
        }
        return null;
      });
    }

    fields = uniq(
      [
        ...defaultFields,
        ...(checkIdProof && !abhyasiDetails?.ref ? alternateNumber : []),
        ...(checkIdProof ? alternateNumber : []),
        ...(checkIdProof ? [filteredFields[filteredFields.length - 1]] : []),
      ],
      (e) => e.name
    );
  } else {
    const defaultFields = checkIdProof
      ? personFormFields.slice(0, personFormFields.length - 1)
      : personFormFields;

    if (
      mandatePanelFields &&
      Array.isArray(mandatePanelFields) &&
      mandatePanelFields.length > 0
    ) {
      mandatePanelFields.map((i) => {
        const index = defaultFields.findIndex((f) => f.name === i);
        if (index >= 0)
          defaultFields[index] = {
            ...defaultFields[index],
            props: { ...(defaultFields[index]?.props || {}), required: true },
          };
        return null;
      });
    }

    fields = [
      ...defaultFields,
      ...(checkIdProof ? [...govFields, ...alternateNumber] : []),
      ...(checkIdProof ? [personFormFields[personFormFields.length - 1]] : []),
    ];
  }

  // As per new requirement this has been commented
  // if (!(fieldName === "email" || fieldName === "mobile")) {
  //   return null;
  // }

  return (
    <div sx={{ py: 2 }}>
      {abhyasiDetails ? (
        <div sx={{ mb: 3, fontSize: "12px", color: "#4b544b" }}>
          We request you to keep the profile updated in{" "}
          <a
            href="https://my.heartfulness.org/"
            target="_blank"
            rel="noreferrer noopener"
            sx={{ color: "#1b6497" }}
          >
            https://my.heartfulness.org/
          </a>{" "}
          to avoid re-entering profile information for each event.
        </div>
      ) : null}
      {abhyasiDetails && fields.length ? (
        <div sx={{ mb: 2 }}>Please enter the missing details</div>
      ) : null}
      {abhyasiDetails && (
        <PersonCard person={abhyasiDetails} readOnly keyObj="" />
      )}
      <DynamicForm
        newFormLayout={undefined}
        defaultValues={data}
        formFields={fields.filter((f) => f.name !== fieldName)}
        formValidator={(r) =>
          validateForm(r, missingFields, abhyasiDetails, conditionProp)
        }
        formClassName="person_form_custom"
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        buttons={{
          submitButton: (
            <PrimaryButton
              type="submit"
              iconProps={{ iconName: "Save" }}
              text="Add"
            />
          ),
          discardButton: (
            <TransparentButton
              iconProps={{ iconName: "Cancel" }}
              text="Discard"
            />
          ),
        }}
      />
    </div>
  );
}

PersonForm.propTypes = {
  onDiscard: PropTypes.func.isRequired,
  fieldName: PropTypes.oneOf(["email", "mobile"]).isRequired,
  fieldValue: PropTypes.string.isRequired,
  abhyasiDetails: PropTypes.shape({}).isRequired,
  PersonCard: PropTypes.func.isRequired,
};

export default PersonForm;
