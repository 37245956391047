/** @jsx jsx */
import { jsx, Box, Image } from "theme-ui";
import { Avatar, SignInButton } from "gatsby-plugin-hfn-profile/auth";
import AppIcons from "sites-common/utils/icons";
import PropTypes from "prop-types";

const Header = ({ goHome }) => {
  return (
    <Box>
      <div>
        <table
          sx={{
            width: "100%",
            opacity: 1,
            py: 3,
            backgroundColor: "#ffffff",
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          <tbody>
            <tr>
              <td>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Image
                      width="200"
                      src={AppIcons.logo}
                      alt="Heartfulness"
                      sx={{ cursor: "pointer", px: 3 }}
                      role="button"
                      tabIndex={0}
                      onClick={goHome}
                      onKeyDown={goHome}
                    />
                  </Box>
                </Box>
              </td>
              <td align="right">
                <Box sx={{ px: 3 }}>
                  <Avatar />
                  <SignInButton btnText="Sign-In" />
                </Box>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Box>
  );
};

Header.propTypes = {
  goHome: PropTypes.func.isRequired,
};

const accessControl = {
  msgUnAuthorized: (r) => `Hello, ${r.name}`,
  showSignInButton: "Sign In to access this page",
  conditions: (r) => !!r,
};

const AppConfig = {
  Header,
  accessControl,
};
export default AppConfig;
