import { get, set, sortBy } from "sites-common/utils/lodash";

import {
  SHOW_ALERT,
  DISMISS_ALERT,

  // SET_GLOBAL_CONFIG,
  INVALIDATE_URL,
  REQUEST_URL,
  RECEIVE_URL,
  RECEIVE_URL_RESULT,
  RESET_URL,
  SET_GLOBALS,
  INIT_SRCM_COLLECTION,
  DESTROY_SRCM_COLLECTION,
  INVALIDATE_SRCM_API_STORE,
  REQUEST_SRCM_API_STORE,
  RECEIVE_SRCM_API_STORE,
  RECEIVE_SRCM_API_STORE_DOC,
  RECEIVE_URL_STAY_RESULT,
  RECEIVE_URL_SAME_STAY,
  RECEIVE_URL_GLOBAL_RESULT,
  RECEIVE_URL_GLOBAL_STAY_RESULT,
  REMOVE_URL,
  RECEIVE_URL_GLOBAL_SAME_STAY,
} from "./constants";

const initialState = {
  alert: null,
  json: {},
  globals: {},
  globalConfig: {},
};

export const initUrlBlob = {
  isLoaded: false,
  isFetching: false,
  errorMessage: "",
  data: null,
};

function url(state = initUrlBlob, action) {
  let results;
  let data;
  let resultsData;
  switch (action.type) {
    case RESET_URL:
      return { ...initUrlBlob };
    case INVALIDATE_URL:
      return { ...state, isFetching: false, errorMessage: action.error };
    case REQUEST_URL:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case RECEIVE_URL:
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        errorMessage: "",
        data: action.data,
        lastUpdated: action.receivedAt,
      };
    case REMOVE_URL:
      results = state.data.filter((item) =>
        action.data.includes(parseInt(item.id, 10))
      );
      data = [
        ...state.data.filter(
          (item) => !action.data.includes(parseInt(item.id, 10))
        ),
        ...results.map((item) => ({
          ...item,
          itemStatus: action.status,
        })),
      ];
      return {
        ...state,
        data,
      };
    case RECEIVE_URL_RESULT:
      if (action.searchType === "pnrs") {
        results = [
          ...(state.data?.results[0]?.registrations.filter(
            (r) => r.id !== action.docId
          ) || []),
          action.docData,
        ];
        [resultsData] = state.data.results;
        data = {
          ...state.data,
          results: [
            {
              ...resultsData,
              registrations: sortBy(results, (r) => r.id),
            },
          ],
        };
      } else {
        results = [
          ...state.data?.results.filter((r) => r.id !== action.docId),
          action.docData,
        ];
        data = {
          ...state.data,
          count: results.length,
          results: sortBy(results, (r) => r.id),
        };
      }

      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        errorMessage: "",
        data,
        lastUpdated: action.receivedAt,
      };

    case RECEIVE_URL_SAME_STAY:
      return {
        ...state,
        data: {
          ...state.data,
          results: [
            {
              ...state.data.results[0],
              same_stay: action.same_stay,
            },
          ],
        },
        lastUpdated: action.receivedAt,
      };

    case RECEIVE_URL_STAY_RESULT:
      results = [
        ...state.data?.results[0].stays.filter((r) => r.id !== action.docId),
        action.docData,
      ];
      [resultsData] = state.data.results;
      data = {
        ...state.data,
        results: [
          {
            ...resultsData,
            stays: sortBy(results, (r) => r.id),
          },
        ],
      };
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        errorMessage: "",
        data,
        lastUpdated: action.receivedAt,
      };

    case RECEIVE_URL_GLOBAL_RESULT:
      data = action.docData;
      results = [
        ...state.data.registrations.filter((item) => item.id !== action.docId),
        {
          ...state.data.registrations.find((item) => item.id === action.docId),
          ...data,
        },
      ].sort((a, b) => a.id - b.id);
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        errorMessage: "",
        data: {
          ...state.data,
          registrations: results,
        },
        lastUpdated: action.receivedAt,
      };

    case RECEIVE_URL_GLOBAL_SAME_STAY:
      return {
        ...state,
        data: {
          ...state.data,
          same_stay: action.same_stay,
        },
        lastUpdated: action.receivedAt,
      };
    case RECEIVE_URL_GLOBAL_STAY_RESULT:
      data = action.docData;
      results = [
        ...state.data.stays.filter((item) => item.id !== action.docId),
        {
          ...state.data.stays.find((item) => item.id === action.docId),
          ...data,
        },
      ].sort((a, b) => a.id - b.id);
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        errorMessage: "",
        data: {
          ...state.data,
          stays: results,
        },
        lastUpdated: action.receivedAt,
      };

    default:
      return state;
  }
}

export const globalReducers = (state = initialState, action) => {
  let r;
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        alert: action.alert,
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alert: null,
      };

    case RESET_URL:
    case INVALIDATE_URL:
    case RECEIVE_URL:
    case RECEIVE_URL_RESULT:
    case RECEIVE_URL_STAY_RESULT:
    case RECEIVE_URL_SAME_STAY:
    case RECEIVE_URL_GLOBAL_RESULT:
    case RECEIVE_URL_GLOBAL_STAY_RESULT:
    case RECEIVE_URL_GLOBAL_SAME_STAY:
    case REQUEST_URL:
    case REMOVE_URL:
      return {
        ...state,
        json: {
          ...state.json,
          [action.key]: url(
            get(state, ["json", action.key], initUrlBlob),
            action
          ),
        },
      };

    // case SET_GLOBAL_CONFIG:
    //   return {
    //     ...state,
    //     globalConfig: action.value,
    //   };

    case SET_GLOBALS:
      if (typeof action.key === "string" && !action.key.includes(".")) {
        return {
          ...state,
          globals: {
            ...state.globals,
            [action.key]: action.value,
          },
        };
      }
      r = {
        ...state,
        globals: {
          ...state.globals,
        },
      };
      set(r.globals, action.key, action.value);
      return r;

    default:
      return state;
  }
};

const initialSrcmCollection = {
  apiParams: {},
  data: {},
  ordered: [],
  count: 0,
};
const srcmcollection = (state = initialSrcmCollection, action) => {
  let count;
  let data;
  let ordered;
  let r;
  let primaryKey;
  switch (action.type) {
    case RECEIVE_SRCM_API_STORE_DOC:
      data = { ...state.data };
      r = action.data;
      data[action.docId] = { ...get(state, ["data", action.docId], {}), ...r };
      ordered = Object.values(data);
      count = Math.max(state.count, ordered.length);
      return { ...state, data, ordered, count };

    case RECEIVE_SRCM_API_STORE:
      data = { ...state.data };
      primaryKey = action.apiParams || "id";
      action.data.results.forEach((res) => {
        let x = res[primaryKey];
        if (primaryKey in res) {
          x = `${res[primaryKey]}`;
        } else {
          x = `${res.name}`;
        }
        data[x] = {
          ...state.data[x],
          ...res,
        };
      });
      count = action.data.count;
      ordered = Object.values(data);
      return { ...state, data, ordered, count };

    default:
      return state;
  }
};

function apicall(state = initUrlBlob, action) {
  switch (action.type) {
    case INVALIDATE_SRCM_API_STORE:
      return { ...state, errorMessage: action.error };
    case REQUEST_SRCM_API_STORE:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case RECEIVE_SRCM_API_STORE:
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        errorMessage: "",
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}
const initialSrcmState = {
  api_calls: {},
  collections: {},
};
export const srcmReducers = (state = initialSrcmState, action) => {
  let n;
  switch (action.type) {
    case RECEIVE_SRCM_API_STORE:
      return {
        collections: {
          ...state.collections,
          [action.collection]: srcmcollection(
            state.collections[action.collection],
            action
          ),
        },
        api_calls: {
          ...state.api_calls,
          [action.key]: apicall(
            get(state, ["api_calls", action.key], initUrlBlob),
            action
          ),
        },
      };
    case RECEIVE_SRCM_API_STORE_DOC:
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.collection]: srcmcollection(
            state.collections[action.collection],
            action
          ),
        },
      };
    case INVALIDATE_SRCM_API_STORE:
    case REQUEST_SRCM_API_STORE:
      return {
        ...state,
        api_calls: {
          ...state.api_calls,
          [action.key]: apicall(
            get(state, ["api_calls", action.key], initUrlBlob),
            action
          ),
        },
      };

    case INIT_SRCM_COLLECTION:
      return {
        ...state,
        collections: {
          // ignore initing collection again if it is already available in state
          [action.collection]: {
            ...initialSrcmCollection,
            apiParams: action.apiParams,
          },
          ...state.collections,
        },
      };

    case DESTROY_SRCM_COLLECTION:
      n = { ...state.collections };
      delete n[action.collection];
      return { ...state, collections: n };

    default:
      return state;
  }
};
